import { Controller } from '@hotwired/stimulus';

/**
 * Periodically refreshes content via a GET request
 * @example
 * %div(data-controller="periodic-refresh" data-periodic-refresh-url="/resource/:id/status" data-periodic-refresh-interval="5000") Content
 */
export default class extends Controller {
  connect() {
    setTimeout(this.refresh, this.data.has('interval') ? parseInt(this.data.get('interval')) : 5000);
  }

  refresh = () => {
    $.get(this.data.get('url')).done((responseText) => $(this.element).replaceWith(responseText));
  };
}
