import { Controller } from '@hotwired/stimulus';
import tippy, { roundArrow } from 'tippy.js';

const DEFAULT_TIPPY_PROPS = {
  theme: 'tooltip', // Theme styles are defined in vendor/tippy.css
  arrow: roundArrow,
  role: 'tooltip',
};

/**
 * Stimulus controller that shows tooltip on hover.
 * @example
 * %span(data-controller="tooltip" data-tooltip-content="Hello") Hover me
 *
 * @example
 * %input(data-controller="tooltip" data-tooltip-content="Type and press enter" data-tooltip-trigger="focus" data-tooltip-hide-on-click="toggle")
 *
 * @example
 * %span(data-controller="tooltip" data-tooltip-content="Hello <strong>world</strong>!" data-tooltip-allow-html) HTML tooltip
 */
export default class extends Controller {
  get portal() {
    return document.getElementById('portal') || document.body;
  }

  // Collects tippy.js props from data attributes (turns data-tooltip-content="Hello" into { content: 'Hello' })
  // See documentation for all props: https://atomiks.github.io/tippyjs/v6/all-props/
  get tippyProps() {
    let props = {};

    for (const key of Object.keys(tippy.defaultProps)) {
      if (this.data.has(key)) {
        props[key] = this.data.get(key);
      }
    }

    props.allowHTML = this.data.has('allowHtml');

    return props;
  }

  connect() {
    this.tippyInstance = tippy(this.element, { ...DEFAULT_TIPPY_PROPS, appendTo: this.portal, ...this.tippyProps });
  }

  disconnect() {
    this.tippyInstance.destroy();
    this.tippyInstance = null;
  }

  enable() {
    this.tippyInstance.enable();
  }

  disable() {
    this.tippyInstance.disable();
  }
}
