import { Controller } from '@hotwired/stimulus';
import React from 'react';
import ReactDOM from 'react-dom';

export default class extends Controller {
  static values = {
    id: String,
    name: String,
    default: Array,
    choices: Array,
    disabled: Boolean,
    disabledChoices: Array,
    multiple: Boolean,
    includeBlank: String,
    prompt: String,
    creatable: Boolean,
    createLabel: String,
    noOptionsMessage: String,
    url: String,
  };

  get portal() {
    return document.getElementById('portal');
  }

  connect() {
    this.render();
  }

  disabledValueChanged(value) {
    this.component?.current.setState({ isDisabled: value });
  }

  async render() {
    const Suggest = await import(`../react_components/suggest.jsx`);
    this.component = React.createRef();
    ReactDOM.render(<Suggest.default ref={this.component} {...this.extractSelectProps()} />, this.element);
  }

  async rerender() {
    ReactDOM.unmountComponentAtNode(this.element);
    this.render();
  }

  extractSelectProps = () => {
    const inputId = this.idValue;
    const name = this.nameValue;
    const options = this.choicesValue.map(makeOption);
    const defaultValue = findOptions(options, this.defaultValue);
    const creatable = this.creatableValue;
    const createLabel = this.createLabelValue;
    const noOptionsMessage = this.noOptionsMessageValue;
    const isMulti = this.multipleValue;
    const isDisabled = this.disabledValue;
    const placeholder = this.includeBlankValue || this.promptValue;
    const isClearable = (this.promptValue && this.defaultValue.length === 0) || this.includeBlankValue || isMulti;
    const url = this.urlValue;
    const menuPlacement = 'auto';
    const menuPosition = this.portal.contains(this.element) ? 'fixed' : 'absolute';
    const menuPortalTarget = this.portal;
    const onChange = this.handleChange;

    return {
      inputId,
      name,
      options,
      defaultValue,
      creatable,
      createLabel,
      noOptionsMessage,
      isMulti,
      isDisabled,
      placeholder,
      isClearable,
      url,
      menuPlacement,
      menuPosition,
      menuPortalTarget,
      onChange,
    };
  };

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.element);
    delete this.component;
  }

  handleChange = (value, meta) => {
    const wrappedValue = value ? [value].flat() : [];
    const event = new CustomEvent('change', { detail: { value: wrappedValue, meta } });

    // Prevent Backspace on empty value dispatch change event
    if (meta.action !== 'clear' || (meta.removedValues && meta.removedValues.length > 0)) {
      // Wait for react-select to rerender hidden inputs before dispatching event
      setTimeout(() => this.element.dispatchEvent(event), 0);
    }
  };
}

function makeOption(choice) {
  const label = choice[0];
  const value = choice[1];
  const isDisabled = choice[2]?.disabled;
  const customLabel = choice[2]?.custom_label;

  if (typeof value === 'object') {
    return { label, options: value.map(makeOption), isDisabled, customLabel };
  } else {
    return { label, value, isDisabled, customLabel };
  }
}

function findOptions(options, values) {
  // Recursively find options that match the given values (with support for grouped options)
  return options.reduce((arr, option) => {
    if (option.options) {
      return [...arr, ...findOptions(option.options, values)];
    } else if (values.includes(option.value)) {
      return [...arr, option];
    } else {
      return arr;
    }
  }, []);
}
