import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['check', 'filter'];

  connect() {
    this.activateFilter('all');
    this.filterChecks('all');
  }

  handleFilter(event) {
    const userId = event.currentTarget.getAttribute('data-user-id');

    this.activateFilter(userId);
    this.filterChecks(userId);
  }

  filterChecks(userId) {
    for (const check of this.checkTargets) {
      const checkUserId = check.getAttribute('data-user-id');
      const match = userId == 'all' || userId == checkUserId;

      $(check).toggleClass('hidden', !match);
    }
  }

  activateFilter(userId) {
    for (const filter of this.filterTargets) {
      const filterUserId = filter.getAttribute('data-user-id');
      const match = filterUserId == userId;

      $(filter).toggleClass('strong', match).toggleClass('bg-grey-light text-grey-dark', !match);
    }
  }
}
