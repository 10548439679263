import ToggleByController from './toggle_by_controller';

/**
 * Disables an element based on the state of a checkbox, radio input, or select.
 */
export default class extends ToggleByController {
  toggle = (isToggled) => {
    $(this.element).prop('disabled', isToggled);
  };
}
