import { Controller } from '@hotwired/stimulus';

/**
 * Expands or collapses (multiple) items via a trigger (such as a button)
 *
 * @example
 * %ul(data-controller="expandable-list")
 *   %li Item 1
 *   %li(data-expandable-list-target="item" style="display: none") Item 2
 *   %li(data-expandable-list-target="item" style="display: none") Item 3
 *   %li.link-like(data-expandable-list-target="expand") Show more...
 *   %li.link-like(data-expandable-list-target="collapse" style="display: none") Show less
 */
export default class extends Controller {
  static targets = ['item', 'expand', 'collapse'];

  connect() {
    $(this.hasExpandTarget && this.expandTarget).on('click', this.handleExpandClick);
    $(this.hasCollapseTarget && this.collapseTarget).on('click', this.handleCollapseClick);
  }

  disconnect() {
    $(this.hasExpandTarget && this.expandTarget).off('click', this.handleExpandClick);
    $(this.hasCollapseTarget && this.collapseTarget).off('click', this.handleCollapseClick);
  }

  handleExpandClick = () => {
    $(this.itemTargets).show();
    $(this.expandTarget).hide();
    $(this.collapseTarget).show();
  };

  handleCollapseClick = () => {
    $(this.itemTargets).hide();
    $(this.expandTarget).show();
    $(this.collapseTarget).hide();
  };
}
