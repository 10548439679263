import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['row', 'detailsRow'];

  connect() {
    this.rowTargets.forEach((row) => {
      row.addEventListener('click', () => {
        // Find the parent table
        const table = row.closest('table');

        // Hide all details rows in the same table except the one being clicked
        this.detailsRowTargets.forEach((detailsRow) => {
          if (detailsRow !== row.nextElementSibling) {
            detailsRow.classList.add('hidden');
            detailsRow.previousElementSibling.classList.add('md:hover:bg-gray-100'); // Add hover class back
          }
        });

        // Show or hide the corresponding details row
        const nextRow = row.nextElementSibling;
        if (nextRow && nextRow.classList.contains('details-row')) {
          nextRow.classList.toggle('hidden');
          if (nextRow.classList.contains('hidden')) {
            row.classList.add('md:hover:bg-gray-100'); // Add hover class back
          } else {
            row.classList.remove('md:hover:bg-gray-100'); // Remove hover class
          }
        }
      });
    });
  }
}
