import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  get key() {
    return this.data.get('key');
  }

  toggleActive(isActive) {
    $(this.element).toggleClass(this.data.get('active-class'), isActive);
    $(this.element).attr('aria-selected', isActive.toString());
  }
}
