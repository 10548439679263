import { Controller } from '@hotwired/stimulus';

/**
 * Like regular select2, but renders `data-count` (if provided) next to select options.
 */
export default class extends Controller {
  get $element() {
    return $(this.element);
  }

  connect() {
    this.$element.select2({
      placeholder: this.$element.data('prompt'),
      allowClear: this.$element.data('required') === undefined,
      templateResult: this.renderTemplateResult,
      language: I18n_locale,
    });
  }

  disconnect() {
    this.$element.select2('destroy');
  }

  renderTemplateResult = (item) => {
    if (!item.element) {
      return item.text;
    }

    const $option = $(item.element);
    const count = $option.data('count');

    if (count > 0) {
      return $(`<span>${item.text}</span><span class="ml-05 text-muted">(${count})</span>`);
    }

    if (count === 0) {
      return $(`<span class="text-muted">${item.text}</span><span class="ml-05 text-muted">(${count})</span>`);
    }

    return item.text;
  };
}
