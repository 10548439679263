import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggle', 'checkbox'];

  get $toggleTarget() {
    return this.hasToggleTarget ? $(this.toggleTarget) : $();
  }

  get visibleCheckboxes() {
    return $(this.checkboxTargets).filter(':visible').toArray();
  }

  connect() {
    this.$toggleTarget.on('change', this.handleToggleChange);
    $(this.visibleCheckboxes).on('change', this.handleCheckboxChange);

    this.handleCheckboxChange();
  }

  disconnect() {
    this.$toggleTarget.off('change', this.handleToggleChange);
    $(this.visibleCheckboxes).off('change', this.handleCheckboxChange);
  }

  /**
   * Use these actions to explicitely select/deselect all checkboxes via a link or button
   * @example
   * a.link(data-action="click->select-all#selectAll") All
   * a.link(data-action="click->select-all#selectNone") None
   */
  selectAll() {
    this.toggleCheckboxes(true);
  }

  selectNone() {
    this.toggleCheckboxes(false);
  }

  handleToggleChange = () => {
    this.toggleCheckboxes(this.toggleTarget.checked);
  };

  toggleCheckboxes(isToggled) {
    for (const checkboxTarget of this.visibleCheckboxes) {
      if (!$(checkboxTarget).prop('disabled')) {
        checkboxTarget.checked = isToggled;
        $(checkboxTarget).trigger('checkboxChange.selectAll');
      }
    }
  }

  handleCheckboxChange = () => {
    const someChecked = this.visibleCheckboxes.some((checkboxTarget) => checkboxTarget.checked);
    const allChecked = this.visibleCheckboxes.every((checkboxTarget) => checkboxTarget.checked);
    const allDisabled = this.visibleCheckboxes.every((checkboxTarget) => $(checkboxTarget).prop('disabled'));

    this.$toggleTarget.prop('checked', allChecked);
    this.$toggleTarget.prop('indeterminate', someChecked && !allChecked);
    this.$toggleTarget.prop('disabled', allDisabled);
    this.$toggleTarget.trigger('toggleChange.selectAll');
  };
}
