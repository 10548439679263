import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

const ANIMATION_DURATION = 300;
const CLONED_ITEM_CLASS = 'relative z-10';

export default class extends Controller {
  static targets = ['item', 'handle', 'position'];

  connect() {
    this.sortable = Sortable.create(this.element, {
      draggable: this.targets.getSelectorForTargetName('item'),
      handle: this.targets.getSelectorForTargetName('handle'),
      animation: ANIMATION_DURATION,
      onStart: this.handleStart,
      onEnd: this.handleEnd,
      chosenClass: this.data.get('chosen-class'),
    });
  }

  disconnect() {
    this.sortable.destroy();
  }

  handleStart = () => {
    this.disableHoverState();
  };

  handleEnd = () => {
    this.enableHoverState();
    this.updatePositions();
    this.element.dispatchEvent(new CustomEvent('reorderable.change', { bubbles: true }));
  };

  disableHoverState() {
    $(this.handleTargets).addClass('pointer-events-none');
  }

  enableHoverState() {
    $(this.handleTargets).removeClass('pointer-events-none');
  }

  updatePositions() {
    $(this.positionTargets).each((index, element) => $(element).val(index));
  }

  moveTop(item) {
    const $item = $(item);
    const $prevItems = $(item).prevAll();

    $item.after(cloneItem).hide();
    $prevItems.after(cloneItem).hide();

    const $clonedItem = $item.next().addClass(CLONED_ITEM_CLASS);
    const $prevClonedItems = $prevItems.next();

    const animations = [
      animate($clonedItem, { now: $prevItems.length * -$item.height() }),
      animate($prevClonedItems, { now: $item.height() }),
    ];

    Promise.all(animations).then(() => {
      $clonedItem.add($prevClonedItems).remove();
      $item.prependTo($item.parent());
      $item.add($prevItems).show();
    });
  }

  moveBottom(item) {
    const $item = $(item);
    const $nextItems = $(item).nextAll();

    $item.after(cloneItem).hide();
    $nextItems.after(cloneItem).hide();

    const $clonedItem = $item.next().addClass(CLONED_ITEM_CLASS);
    const $nextClonedItems = $nextItems.next();

    const animations = [
      animate($clonedItem, { now: $nextItems.length * $item.height() }),
      animate($nextClonedItems, { now: -$item.height() }),
    ];

    Promise.all(animations).then(() => {
      $clonedItem.add($nextClonedItems).remove();
      $item.appendTo($item.parent());
      $item.add($nextItems).show();
    });
  }
}

function cloneItem() {
  return $(this).clone();
}

function animate($element, { now }) {
  const step = (y, { elem }) => $(elem).css('transform', `translateY(${Math.round(y)}px)`);
  return $element.animate({ now }, { duration: ANIMATION_DURATION, step }).promise();
}
