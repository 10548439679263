import React from 'react';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { tailwindConfig } from '../lib/tailwind';

// Manage isDisabled via state, so it can be changed after initial render (in Stimulus controller via a ref)
export default class SuggestWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isDisabled: this.props.isDisabled };
  }

  render() {
    return <Suggest {...this.props} isDisabled={this.state.isDisabled} />;
  }
}

function Suggest({ options, creatable, createLabel, noOptionsMessage, url, ...restProps }) {
  const formatCreateLabel = (label) => createLabel.replace('${option}', label);
  const formatNoOptionsMessage = () => noOptionsMessage;

  const selectProps = {
    classNamePrefix: 'suggest',
    theme: makeTheme,
    formatOptionLabel,
    formatGroupLabel,
    ...restProps,
  };

  if (url) {
    function loadOptions(inputValue, callback) {
      $.getJSON(url, { query: inputValue }).then(callback);
    }

    return <AsyncSelect defaultOptions loadOptions={loadOptions} {...selectProps} />;
  } else if (creatable) {
    return (
      <CreatableSelect options={options} formatCreateLabel={formatCreateLabel} noOptionsMessage={formatNoOptionsMessage} {...selectProps} />
    );
  } else {
    return <ReactSelect options={options} {...selectProps} />;
  }
}

function makeTheme(theme) {
  return {
    ...theme,
    borderRadius: tailwindConfig.theme.borderRadius.DEFAULT,
    colors: {
      ...theme.colors,
      primary: tailwindConfig.theme.colors.blue['500'],
      primary75: tailwindConfig.theme.colors.blue['400'],
      primary50: tailwindConfig.theme.colors.blue['300'],
      primary25: tailwindConfig.theme.colors.blue['100'],
      danger: tailwindConfig.theme.colors.red['500'],
      dangerLight: tailwindConfig.theme.colors.red['100'],
      neutral0: tailwindConfig.theme.colors.white,
      neutral5: tailwindConfig.theme.colors.gray['50'],
      neutral10: tailwindConfig.theme.colors.gray['100'],
      neutral20: tailwindConfig.theme.colors.gray['200'],
      neutral30: tailwindConfig.theme.colors.gray['300'],
      neutral40: tailwindConfig.theme.colors.gray['400'],
      neutral50: tailwindConfig.theme.colors.gray['500'],
      neutral60: tailwindConfig.theme.colors.gray['600'],
      neutral70: tailwindConfig.theme.colors.gray['700'],
      neutral80: tailwindConfig.theme.colors.gray['800'],
      neutral90: tailwindConfig.theme.colors.gray['900'],
    },
  };
}

function formatOptionLabel(option) {
  return option.customLabel ? <div dangerouslySetInnerHTML={{ __html: option.customLabel }} /> : option.label;
}

function formatGroupLabel(option) {
  return formatOptionLabel(option);
}
