import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['item', 'panel'];

  connect() {
    $(this.itemTargets).on('click', this.handleItemClick);
  }

  disconnect() {
    $(this.itemTargets).off('click', this.handleItemClick);
  }

  handleItemClick = (event) => {
    const activeTabsItemController = this.application.getControllerForElementAndIdentifier(event.currentTarget, 'tabs-item');
    this.setActive(activeTabsItemController.key);
  };

  setActive(key) {
    for (const itemTarget of this.itemTargets) {
      const tabsItemController = this.application.getControllerForElementAndIdentifier(itemTarget, 'tabs-item');
      tabsItemController.toggleActive(tabsItemController.key === key);
    }

    for (const panelTarget of this.panelTargets) {
      const tabsPanelController = this.application.getControllerForElementAndIdentifier(panelTarget, 'tabs-panel');
      tabsPanelController.toggleActive(tabsPanelController.key === key);
    }
  }
}
