import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['fileInput', 'image', 'removeInput', 'preview'];

  preview() {
    let reader = new FileReader();
    this.removeInputTarget.value = 0;

    reader.onload = (e) => {
      this.imageTarget.src = e.target.result;
      this.previewTarget.style.display = 'block';
    };

    if (this.fileInputTarget.files.length > 0) {
      reader.readAsDataURL(this.fileInputTarget.files[0]);
    }
  }

  removeImage() {
    this.previewTarget.style.display = 'none';
    this.removeInputTarget.value = 1;
    this.fileInputTarget.value = '';
  }
}
