import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  handleClick(event) {
    event.preventDefault();

    AjaxPopover.load($(this.element), this.data.get('url')).done(() => {
      if (this.data.get('done') === 'reload') {
        window.location.reload();
      }
    });
  }
}
