import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['subtotal', 'difference'];

  connect() {
    this.updateDifference();
  }

  handleSubtotalChange() {
    this.updateDifference();
  }

  updateDifference() {
    const registerTotal = parseFloat(this.data.get('total'));
    const fieldTotal = parseFloat($(this.subtotalTarget).val());
    const difference = fieldTotal - registerTotal;

    $(this.differenceTarget).val(difference.toFixed(2));
  }
}
