import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.$form = $(this.element).closest('form');
  }

  handleClick(event) {
    this.$form.append([
      '<input type="hidden" name="details[range_id]" value="' + event.currentTarget.dataset['rangeId'] + '">',
      '<input type="hidden" name="details[breakdown_item_ids]" value="' + event.currentTarget.dataset['breakdownItemIds'] + '">',
      '<input type="hidden" name="details[label]" value="' + event.currentTarget.dataset['label'] + '">',
      '<input type="hidden" name="details[color]" value="' + event.currentTarget.dataset['color'] + '">',
    ]);
    this.$form.submit();
  }
}
