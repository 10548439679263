import ToggleByController from './toggle_by_controller';

/**
 * Enables an element based on the state of a checkbox, radio input, or select.
 * @example
 * %input(value="value1" type="radio" name="my-radio-input") Radio 1
 * %input(value="value2" type="radio" name="my-radio-input") Radio 2
 * %input(value="value3" type="radio" name="my-radio-input") Radio 3
 *
 * %input(value="Enabled when `my-radio-input = value1`" data-controller="enable-by" data-enable-by-radio="[name=my-radio-input]" data-enable-by-value="value1")
 * %input(value="Enabled when `my-radio-input = value1 or value2`" data-controller="enable-by" data-enable-by-radio="[name=my-radio-input]" data-enable-by-values="value1,value2")
 *
 * @example
 * %input(type="checkbox" name="my-checkbox-input") Checkbox
 * %input(value="Enabled when `my-checkbox-input` is checked" data-controller="enable-by" data-enable-by-checkbox="[name=my-checkbox-input]")
 *
 * @example
 * %select(id="my_select")
 *   %option(value="value1") Value 1
 *   %option(value="value2") Value 2
 *
 * %input(type="text" value="Hello" data-controller="enable-by" data-enable-by-select="#my_select" data-enable-by-value="value1") Enabled when `Value 1` is selected
 */
export default class extends ToggleByController {
  toggle = (isToggled) => {
    $(this.element).prop('disabled', !isToggled);
  };
}
