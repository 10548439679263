import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    $(this.element).on('change', this.handleChange);
  }

  disconnect() {
    $(this.element).off('change', this.handleChange);
  }

  handleChange = () => {
    if (this.element.value) {
      if (this.element.value % 1 === 0) {
        this.element.value = parseFloat(this.element.value).toFixed(0);
      } else {
        this.element.value = parseFloat(this.element.value).toFixed(2);
      }
    }
  };
}
