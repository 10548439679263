import { Controller } from '@hotwired/stimulus';

const ENQUEUE_DELAY = 1000;
const SLIDE_DELAY = 500;
const HIDE_DELAY = 15000;

export default class extends Controller {
  static targets = ['notification'];

  connect() {
    for (let i = 0; i < this.notificationTargets.length; i++) {
      const delay = 500 + ENQUEUE_DELAY * i;
      setTimeout(() => this.addNotification($(this.notificationTargets[i])), delay);
    }
  }

  addNotification($notification, hideDelay = HIDE_DELAY) {
    $notification.slideDown(SLIDE_DELAY); // prettier-ignore
    setTimeout(() => $notification.slideUp(SLIDE_DELAY, () => $(this).remove()), hideDelay);
  }
}
