import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['query', 'item', 'empty'];

  get hideClass() {
    return this.data.has('hideClass') ? this.data.get('hideClass') : 'hidden';
  }

  get hideAttribute() {
    return this.data.get('hideAttribute');
  }

  connect() {
    this.tokenizeItems();
    this.applyQuery();
    $(this.queryTarget).on('search', this.handleSearch);
  }

  disconnect() {
    $(this.queryTarget).off('search', this.handleSearch);
  }

  handleSearch = () => {
    this.applyQuery();
  };

  tokenizeItems() {
    this.tokenizedItems = [];

    this.itemTargets.forEach((item) => {
      this.tokenizedItems.push({ item, itemToken: item.textContent.toLowerCase().trim() });
    });
  }

  applyQuery() {
    const queryTokens = this.queryTarget.value.toLowerCase().split(/\s+/);

    if (queryTokens.length === 0) {
      return;
    }

    const matchingItems = [];
    const nonMatchingItems = [];

    this.tokenizedItems.forEach(({ item, itemToken }) => {
      if (queryTokens.every((queryToken) => itemToken.includes(queryToken))) {
        matchingItems.push(item);
      } else {
        nonMatchingItems.push(item);
      }
    });

    $(matchingItems).removeClass(this.hideClass);
    $(nonMatchingItems).addClass(this.hideClass);
    $(matchingItems).removeAttr(this.hideAttribute);
    $(nonMatchingItems).attr(this.hideAttribute, true);

    $(this.hasEmptyTarget && this.emptyTarget).toggle(matchingItems.length === 0);
  }
}
