import { Controller } from '@hotwired/stimulus';

export function parseDecimal(value) {
  const decimalValue = parseFloat((value || '0').replace(',', '.'));
  return decimalValue ? decimalValue : 0.0;
}

export default class extends Controller {
  static targets = ['percentage', 'rest'];

  connect() {
    $(document).on('change', this.targets.getSelectorForTargetName('percentage'), this.handlePercentageKeyup);
    $(this.element).on('nestedFormSpawn', this.handleNestedFormAddOrRemove);
    $(this.element).on('nestedFormRemove', this.handleNestedFormAddOrRemove);
  }

  handlePercentageKeyup = () => {
    const rest = this.percentageTargets.reduce((total, item) => (total += parseDecimal(item.value)), 0.0);
    $(this.restTarget).val(100.0 - rest);
  };

  handleNestedFormAddOrRemove = () => {
    const percentage = Math.floor(100.0 / (this.percentageTargets.length + 1));
    const remaining = 100.0 - this.percentageTargets.length * percentage;
    $(this.percentageTargets).each((index, item) => $(item).val(percentage));
    $(this.restTarget).val(Math.max(remaining, 0));
  };
}
