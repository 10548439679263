import { Controller } from '@hotwired/stimulus';

const standardOptions = {
  credits: {
    enabled: false,
  },
  chart: {
    type: 'heatmap',
    plotBorderWidth: 0,
  },
  title: {
    text: null,
  },
  tooltip: {
    useHTML: true,
    formatter: function () {
      return `<b>${this.point.value}</b>`;
    },
  },
  xAxis: {
    title: null,
    gridLineWidth: 0,
    tickWidth: 0,
    lineWidth: 0,
    opposite: true,
    labels: {
      formatter: function () {
        if (typeof this.value === 'string') {
          return this.value;
        } else {
          return '';
        }
      },
    },
  },
  yAxis: {
    title: null,
    gridLineWidth: 0,
    labels: {
      formatter: function () {
        if (typeof this.value === 'string') {
          return this.value;
        } else {
          return '';
        }
      },
    },
  },
  colorAxis: {
    min: -100,
    max: 100,
    stops: [
      [0, '#FF0000'],
      [0.5, '#FFFFFF'],
      [1, '#00802c'],
    ],
  },
  legend: {
    enabled: false,
  },
};

export default class extends Controller {
  static targets = ['container', 'data', 'tooltip', 'xIndexInput', 'yIndexInput'];

  connect() {
    this.$container = $(this.containerTarget);
    this.$form = $(this.element).closest('form');
    this.$xIndexInput = $(this.xIndexInputTarget);
    this.$yIndexInput = $(this.yIndexInputTarget);
    this.chartData = $.parseJSON($(this.dataTarget).html());
    this.render();
  }

  render() {
    const heatmap = this;

    const plotOptions = {
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click: function () {
              heatmap.handleClick(this);
            },
          },
        },
      },
    };

    const tooltip = {
      useHTML: true,
      formatter: function () {
        return heatmap.formatTooltip(this.point);
      },
    };

    const renderOptions = { plotOptions, tooltip };

    if (this.chartData.compare) {
      this.chartData.chart_options.series[0].dataLabels.formatter = function () {
        if (this.point.value > 0) {
          return `+${this.point.value}`;
        } else {
          return this.point.value;
        }
      };
    }

    const highChartOptions = $.extend(true, {}, standardOptions, this.chartData.chart_options, renderOptions);
    new Highcharts.Chart(this.$container.attr('id'), highChartOptions);
  }

  formatTooltip(point) {
    if (point.value === 0) {
      return false;
    }

    const tooltip = $(this.tooltipTargets).filter(`[data-y-index='${point.y}'][data-x-index='${point.x}']`).html();

    return tooltip || `<b>${point.value}</b>`;
  }

  handleClick(point) {
    this.$yIndexInput.val(point.y);
    this.$xIndexInput.val(point.x);
    this.$form.submit();
  }
}
