import { Controller } from '@hotwired/stimulus';

const AUTOFILL_FIELDS = [
  'email',
  'locale',
  'title',
  'last_name',
  'first_name',
  'address',
  'postalcode',
  'city',
  'phone',
  'country_code',
  'company',
  'vat_nr',
  'date_of_birth',
];

export default class extends Controller {
  static targets = ['company', 'lastName'];

  connect() {
    $(this.companyTarget).autocomplete({ source: this.fetchCustomers('company'), select: this.onSelect });
    $(this.lastNameTarget).autocomplete({ source: this.fetchCustomers('last_name'), select: this.onSelect });
  }

  fetchCustomers = (autocompleteField) => {
    console.log(autocompleteField, 'autocompleteField');
    return (request, response) => {
      let params = {};
      params[`search[${autocompleteField}]`] = request.term;
      $.getJSON(this.data.get('source-url'), params).then(this.handleAjaxSucces(response, autocompleteField));
    };
  };

  onSelect = (event, ui) => {
    if (ui.item) {
      for (const field of AUTOFILL_FIELDS) {
        const value = ui.item[field];
        const $field = $(this.element).find(`#reservations_request_booking_${field}, #booking_${field}`);
        const $value = $(
          `[name="reservations_request[booking][${field}]"][value="${value}"], [name="booking[${field}]"][value="${value}"]`
        );

        if ($field.length > 0) {
          $field.val(value).trigger('customerAutocomplete.change');

          if ($field.data('controller') && $field.data('controller').includes('select2')) {
            $field.trigger('change');
          }
        }

        if ($value.length > 0) {
          $value.prop('checked', true).trigger('customerAutocomplete.change');
        }
      }

      $('#reservations_request_booking_receive_newsletter, #booking_receive_newsletter').attr('checked', ui.item.receive_newsletter);
    }
  };

  handleAjaxSucces = (response, autocompleteField) => {
    return (data) => {
      const results = $.map(data, (item) => {
        let label = '';

        if (item.company && item.company.length > 0) {
          label += item.company + ' - ';
        }
        if (item.first_name && item.first_name.length > 0) {
          label += item.first_name + ' ';
        }
        if (item.last_name && item.last_name.length > 0) {
          label += item.last_name;
        }
        if (item.address && item.address.length > 0) {
          label += ', ' + item.address;
        }
        if (item.city && item.city.length > 0) {
          label += ', ' + item.city;
        }

        let customerData = {};

        customerData['label'] = label;
        customerData['value'] = item[autocompleteField];
        customerData['receive_newsletter'] = item.receive_newsletter;

        for (const field of AUTOFILL_FIELDS) {
          customerData[field] = item[field];
        }

        return customerData;
      });

      response(results);
    };
  };
}
