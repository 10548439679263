import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['menu'];

  initialize() {
    window.userTasks = this;
    // Note: We can't pass the param from the template using data attributes, because
    // the PMS application header is cached...
    if (new URLSearchParams(document.location.search).get('open_user_tasks_menu') === 'true') {
      this.show();
    }
  }

  connect() {
    $(this.element).on('mousedown', this.handleMouseDown);
  }

  disconnect() {
    $(this.element).off('mousedown', this.handleMouseDown);
  }

  handleMouseDown = (e) => {
    if (!this.menuTarget.contains(e.target)) {
      this.load();
    }
  };

  open = () => {
    $(this.element).addClass('active');
  };

  load() {
    $(this.menuTarget).load(this.data.get('url'));
  }

  scrollIntoView() {
    this.element.parentElement.scrollIntoView({ behavior: 'smooth' });
  }

  show() {
    this.open();
    this.load();
    this.scrollIntoView();
  }
}
