import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['organizationRoleCheckbox', 'parkAuthorizationCheckbox', 'onCallSettingsWrapper'];

  connect() {
    $(this.organizationRoleCheckboxTargets).on('change', this.handleOrganizationRoleCheckboxChange);
    $(this.parkAuthorizationCheckboxTargets).on('change', this.handleParkAuthorizationCheckboxChange);
  }

  disconnect() {
    $(this.organizationRoleCheckboxTargets).off('change', this.handleOrganizationRoleCheckboxChange);
    $(this.parkAuthorizationCheckboxTargets).off('change', this.handleParkAuthorizationCheckboxChange);
  }

  // Event handlers

  handleOrganizationRoleCheckboxChange = (event) => {
    // Update dependent organization role permission check boxes
    this.updateDependentCheckboxes($(event.currentTarget), $(this.organizationRoleCheckboxTargets));

    // Update park authorization permission check boxes
    for (const organizationRoleCheckbox of $(this.organizationRoleCheckboxTargets)) {
      const $organizationRoleCheckbox = $(organizationRoleCheckbox);
      const organizationRoleCheckboxIsChecked = $organizationRoleCheckbox.is(':checked');

      // prettier-ignore
      const $relevantParkAuthorizationCheckboxes =
        this.filterCheckboxesForPermission(
          $(this.parkAuthorizationCheckboxTargets),
          $organizationRoleCheckbox.data('permission')
        );

      for (const parkAuthorizationCheckbox of $relevantParkAuthorizationCheckboxes) {
        const $parkAuthorizationCheckbox = $(parkAuthorizationCheckbox);

        // Controls the 'shadow' hidden box. If the type=checkbox element is enabled and,
        // checked, it's value will override this one
        $parkAuthorizationCheckbox
          .siblings('input[type="hidden"]:not([disabled])')
          .prop('value', organizationRoleCheckboxIsChecked ? '1' : '0');

        if ($parkAuthorizationCheckbox.data('organizationControlled')) {
          $parkAuthorizationCheckbox.prop('checked', organizationRoleCheckboxIsChecked);
        } else if (organizationRoleCheckboxIsChecked) {
          $parkAuthorizationCheckbox.prop('checked', true);
          $parkAuthorizationCheckbox.prop('disabled', true);
        } else {
          $parkAuthorizationCheckbox.prop('disabled', false);
        }
      }
    }

    this.toggleOnCallSettingsWrapperIfRequired();
  };

  handleParkAuthorizationCheckboxChange = (event) => {
    this.updateDependentParkAuthorizationCheckboxes($(event.currentTarget));
    this.toggleOnCallSettingsWrapperIfRequired();
  };

  // Helpers

  updateDependentParkAuthorizationCheckboxes($changedParkAuthorizationCheckbox) {
    const $dependentParkAuthorizationCheckboxes = $(this.parkAuthorizationCheckboxTargets).filter(
      `[data-park-id='${$changedParkAuthorizationCheckbox.data('parkId')}']`
    );

    this.updateDependentCheckboxes($changedParkAuthorizationCheckbox, $dependentParkAuthorizationCheckboxes);
  }

  updateDependentCheckboxes($changedCheckbox, $checkboxes) {
    if ($changedCheckbox.is(':checked')) {
      const changedCheckboxPermissionDependencies = $changedCheckbox.data('permissionDependencies');

      for (const checkbox of $checkboxes) {
        const $checkbox = $(checkbox);

        if ($checkbox.is(':disabled') || $checkbox.is($changedCheckbox)) {
          continue;
        } else if (changedCheckboxPermissionDependencies.includes($checkbox.data('permission'))) {
          $checkbox.prop('checked', true);
        }
      }
    } else {
      const changedCheckboxPermission = $changedCheckbox.data('permission');

      for (const checkbox of $checkboxes) {
        const $checkbox = $(checkbox);

        if ($checkbox.is(':disabled') || $checkbox.is($changedCheckbox)) {
          continue;
        } else if ($checkbox.data('permissionDependencies').includes(changedCheckboxPermission)) {
          $checkbox.prop('checked', false);
        }
      }
    }
  }

  toggleOnCallSettingsWrapperIfRequired() {
    const filteredCheckboxes = this.filterCheckboxesForPermission($(this.parkAuthorizationCheckboxTargets), 'on_call_permission');
    const anyChecked = filteredCheckboxes.filter(':checked').length > 0;

    $(this.onCallSettingsWrapperTarget).toggle(anyChecked);
  }

  filterCheckboxesForPermission($checkboxes, permission) {
    return $checkboxes.filter(`[data-permission='${permission}']`);
  }
}
