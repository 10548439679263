import { Controller } from '@hotwired/stimulus';
import FormUpdater from './form_updater';

export default class extends Controller {
  static targets = ['forPets', 'guestGroupComposition', 'numberOfPetsColumn'];

  constructor() {
    super(...arguments);
    // Update form by POST request, since for long stay reservations params can cause a 414 in a GET request
    this.formUpdater = new FormUpdater(this, 'POST');
    this.oldValue = null;
  }

  connect() {
    $(this.forPetsTargets).on('click', this.handleForPetsClick);
    $(this.guestGroupCompositionTarget).on('focus', 'input[data-value-type]', this.handleInputFocus);
    $(this.guestGroupCompositionTarget).on('change', 'input[data-value-type]', this.handleInputChange);
    $(this.element).on('change', '.js-form--mutator', this.handleMutatorChange);
  }

  disconnect() {
    $(this.forPetsTargets).off('click', this.handleForPetsClick);
    $(this.guestGroupCompositionTarget).off('focus', 'input[data-value-type]', this.handleInputFocus);
    $(this.guestGroupCompositionTarget).off('change', 'input[data-value-type]', this.handleInputChange);
    $(this.element).off('change', '.js-form--mutator', this.handleMutatorChange);
  }

  handleForPetsClick = (e) => {
    const $input = $(e.currentTarget);
    const $compositionTable = $(this.guestGroupCompositionTarget);
    const $numberOfPetsColumns = $(this.numberOfPetsColumnTargets);

    if ($input.is(':checked')) {
      if ($input.val() === 'false') {
        $compositionTable.find('tbody input[data-value-type="number-of-pets"]').val(0);
        $compositionTable.find('tfoot td[data-value-type="number-of-pets"]').text(0);
        $numberOfPetsColumns.hide();

        this.handleMutatorChange();
      } else {
        $numberOfPetsColumns.show();
      }
    }
  };

  handleInputFocus = (e) => {
    this.oldValue = parseInt(e.currentTarget.value);
  };

  handleInputChange = (e) => {
    const $input = $(e.currentTarget);
    const $compositionTable = $(this.guestGroupCompositionTarget);
    const value = parseInt($input.val());

    if (isNaN(value)) {
      return;
    }

    const dataSelector = `[data-value-type="${$input.data('value-type')}"]`;
    const allInputs = $compositionTable.find(`tbody tr input${dataSelector}`).toArray();
    const inputIndex = $input.closest('tr').get(0).rowIndex; // Note: returns CSS index which is 1-based

    // Skip current input
    const slicedInputs = allInputs.slice(inputIndex);

    for (let i = 0; i < slicedInputs.length; i++) {
      const inputElement = $(slicedInputs[i]);
      const inputValue = parseInt(inputElement.val());

      if (isNaN(inputValue) || this.oldValue === inputValue) {
        inputElement.val(value);
      }
    }

    this.oldValue = value;

    const values = allInputs.map((input) => parseInt($(input).val()) || 0);
    const total = values.reduce((sum, v) => sum + v);
    const $totalField = $compositionTable.find(`tfoot tr td${dataSelector}`);

    $totalField.text(total);
    $totalField.effect('highlight', { color: HIGHLIGHT_COLOR }, HIGHLIGHT_DURATION);
  };

  handleMutatorChange = () => {
    this.formUpdater.refreshForm(this.data.get('refresh-url'));
  };
}
