import RowSelectionController from '../row_selection_controller';

export default class extends RowSelectionController {
  completeNewRow($newRow, newRowData) {
    super.completeNewRow($newRow, newRowData);

    $newRow.children('.cost_link').html(newRowData.costLink);
    $newRow.children('.selectable').html(newRowData.selectable);
    $newRow.children('.quantity_type').html(newRowData.quantityType);

    if (!newRowData.requiresQuantity) {
      $newRow.find('.quantity input').prop('disabled', true);
    }

    $newRow.find('.current_default_amount input').val(newRowData.currentDefaultAmount);
    $newRow.find('.price input').val(newRowData.currentDefaultAmount);
  }
}
