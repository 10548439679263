import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['autofill'];

  handleAutofillAllClick(event) {
    event.preventDefault();

    for (const [index, autofillTarget] of Object.entries(this.autofillTargets)) {
      setTimeout(() => $(autofillTarget).trigger('click'), 500 * index);
    }
  }
}
