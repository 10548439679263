import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form'];

  get url() {
    return this.data.get('url');
  }

  connect() {
    $(this.element).load(this.url);
    $(this.element).on('ajax:complete', this.targets.getSelectorForTargetName('form'), this.handleFormAjaxComplete);
  }

  disconnect() {
    $(this.element).off('ajax:complete', this.targets.getSelectorForTargetName('form'), this.handleFormAjaxComplete);
  }

  handleFormAjaxComplete = (event, xhr, status) => {
    switch (xhr.status) {
      case 201:
        // (Re)-disable form elements while redirecting
        // See https://github.com/rails/jquery-ujs/blob/master/src/rails.js#L510
        setTimeout(() => $.rails.disableFormElements($(this.formTarget)), 14);
        window.location.reload();
        break;

      case 422:
        $(this.element).html(xhr.responseText);
        break;
    }
  };
}
