import { Controller } from '@hotwired/stimulus';

const ENQUEUE_DELAY = 1000;
const SLIDE_DELAY = 500;
const HIDE_DELAY = 15000;

export default class extends Controller {
  static targets = ['template', 'alert'];

  initialize() {
    window.alertBox = this;
  }

  connect() {
    for (let i = 0; i < this.alertTargets.length; i++) {
      const delay = 500 + ENQUEUE_DELAY * i;
      setTimeout(() => this.addAlert($($(this.alertTargets[i]).html())), delay);
    }
  }

  addMessage(message, type = 'notice', hideDelay = HIDE_DELAY) {
    const alertHtml = $(this.templateTarget).html().replace('{{message}}', message).replace('{{type}}', type).trim();

    this.addAlert($(alertHtml), hideDelay);
  }

  addAlert($alert, hideDelay = HIDE_DELAY) {
    $alert.hide().appendTo(this.element).slideDown(SLIDE_DELAY); // prettier-ignore
    setTimeout(() => $alert.slideUp(SLIDE_DELAY, () => $(this).remove()), hideDelay);
  }
}
