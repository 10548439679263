import { Controller } from '@hotwired/stimulus';

const ZOOM_LEVEL = 13;

export default class extends Controller {
  static targets = ['map', 'address'];

  connect() {
    // Render map
    this.map = new google.maps.Map(this.mapTarget, { center: { lat: 52.2393909, lng: 6.8366674 }, zoom: ZOOM_LEVEL });

    // Render infowindow to show more information about the parks
    this.infoWindow = new google.maps.InfoWindow();

    // Render autocomplete to search locations
    this.locationSearch = new google.maps.places.Autocomplete(this.addressTarget);

    this.getUserLocation();

    this.load();

    google.maps.event.addListener(this.locationSearch, 'place_changed', this.handleMapPlaceChanged);
  }

  disconnect() {
    google.maps.event.removeListener(this.locationSearch, 'place_changed', this.handleMapPlaceChanged);
  }

  async load() {
    const response = await fetch(this.data.get('url'));
    const parkLocations = await response.json();
    this.renderMarkersOnMap(parkLocations);
  }

  getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          var pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          new google.maps.Marker({ position: pos, map: this.map });
          this.map.setCenter(pos);
        },
        () => {
          this.infoWindow.setPosition(this.map.getCenter());
          this.infoWindow.setContent('The Geolocation service failed.');
          this.infoWindow.open(this.map);
        },
        {
          maximumAge: 10000,
          timeout: 10000,
          enableHighAccuracy: true,
        }
      );
    } else {
      // Browser doesn't support Geolocation
      this.infoWindow.setPosition(this.map.getCenter());
      this.infoWindow.setContent("Error: Your browser doesn't support geolocation.");
      this.infoWindow.open(this.map);
    }
  };

  renderMarkersOnMap = (parkLocations) => {
    parkLocations.map((location, i) => {
      if (location.lat != null && location.lng != null) {
        // NOTE: Adds marker to map
        var parkMarker = new google.maps.Marker({
          position: { lat: location.lat, lng: location.lng },
          map: this.map,
        });

        var infoContent = `
          <div class="map-info-window vspace-2">
            <h1 class="hspace-025 font-larger light">${location.name}</h1>
            <div>
              <p class="map-info-window__paragraph"><b>Adres:</b> ${location.address}
              <p class="map-info-window__paragraph"><b>Postcode:</b> ${location.postalcode}
              <p class="map-info-window__paragraph"><b>Stad:</b> ${location.city}
              <p class="map-info-window__paragraph"><b>Tel:</b> ${location.phone}
            </div>
          </div>
        `;

        parkMarker.addListener('click', () => {
          this.infoWindow.close();
          this.infoWindow.setPosition(parkMarker.position);
          this.infoWindow.open(this.map, parkMarker);
          this.infoWindow.setContent(infoContent);
        });
      }
    });
  };

  handleAddressKeydown(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  handleMapPlaceChanged = () => {
    var place = this.locationSearch.getPlace();
    if (place.geometry) {
      this.map.setCenter(place.geometry.location);
      new google.maps.Marker({ position: place.geometry.location, map: this.map });
    }
  };
}
