import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  get $children() {
    return $(`[data-financial-period-select-parent="${this.data.get('children')}"]`);
  }

  get $parent() {
    return $(`[data-financial-period-select-children="${this.data.get('parent')}"]`);
  }

  get allChildrenChecked() {
    return this.$children.toArray().every((child) => $(child).prop('checked'));
  }

  connect() {
    $(this.element).on('change', this.handleChange);
    $(this.element).on('financialPeriodSelect.parentChange', this.parentChange);
    $(this.element).on('financialPeriodSelect.childChange', this.childChange);
  }

  disconnect() {
    $(this.element).off('change', this.handleChange);
    $(this.element).off('financialPeriodSelect.parentChange', this.parentChange);
    $(this.element).off('financialPeriodSelect.childChange', this.childChange);
  }

  handleChange = () => {
    this.sendToParent();
    this.sendToChildren();
  };

  sendToParent = () => {
    this.$parent.trigger('financialPeriodSelect.childChange');
  };

  sendToChildren = () => {
    for (const child of this.$children) {
      $(child).trigger('financialPeriodSelect.parentChange', $(this.element).prop('checked'));
    }
  };

  childChange = () => {
    $(this.element).prop('checked', this.allChildrenChecked);
    this.sendToParent();
  };

  parentChange = (_, isChecked) => {
    $(this.element).prop('checked', isChecked);
    this.sendToChildren();
  };
}
