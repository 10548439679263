import BaseChartController from '../base_chart_controller';

export default class extends BaseChartController {
  static targets = [...super.targets, 'startDateInput', 'endDateInput'];

  connect() {
    this.$startDateInput = $(this.startDateInputTarget);
    this.$endDateInput = $(this.endDateInputTarget);
    super.connect();
  }

  handleClick(point) {
    if (!point.series.userOptions.priceline) {
      if (point.series._i === 0) {
        this.$startDateInput.val(point.rangeId);
      } else {
        this.$endDateInput.val(point.rangeId);
      }

      return this.$form.submit();
    }
  }
}
