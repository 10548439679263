import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggle', 'checkbox', 'actions', 'count', 'actionSelect'];

  get selectedCount() {
    return this.checkboxTargets.filter((checkboxTarget) => checkboxTarget.checked && !checkboxTarget.disabled).length;
  }

  get $toggleTarget() {
    return this.hasToggleTarget ? $(this.toggleTarget) : $();
  }

  connect() {
    this.toggleActions();
    this.$toggleTarget.on('change toggleChange.selectAll', this.handleToggleChange);
    $(this.checkboxTargets).on('change checkboxChange.selectAll', this.handleCheckboxChange);

    this.updateToggleTarget();
    this.updateActionsTarget();
    this.updateCountTarget();
    this.checkboxTargets.forEach((checkboxTarget) => this.updateCheckboxTarget(checkboxTarget));
    if (this.hasActionSelectTarget) {
      this.toggleCheckboxesForAction();
    }
  }

  disconnect() {
    this.$toggleTarget.off('change toggleChange.selectAll', this.handleToggleChange);
    $(this.checkboxTargets).off('change checkboxChange.selectAll', this.handleCheckboxChange);
  }

  toggleCheckboxesForAction = () => {
    const selectedAction = this.actionSelectTarget.value;

    if (this.selectedCount > 0) {
      for (const checkbox of this.checkboxTargets) {
        $(checkbox).prop('checked', false).trigger('change');
      }
    }

    for (const checkbox of this.checkboxTargets) {
      const $checkbox = $(checkbox);
      const disabled = !selectedAction || $checkbox.data('unsupported-actions')[selectedAction];

      if ($checkbox.prop('disabled') !== disabled) {
        $checkbox.prop('disabled', !!disabled).trigger('change');
        let tooltip = $($checkbox.data('tooltip-id'));
        tooltip.attr('data-title', disabled);
        tooltip.toggle(!!disabled && selectedAction);
      }
    }

    App.tooltips();
    this.updateActionsTarget();
    this.toggleActions();
  };

  toggleActions = () => {
    const newSelectedCount = this.selectedCount;
    const actionSelected = !this.hasActionSelectTarget || this.actionSelectTarget.value != '';

    $(this.hasActionsTarget && this.actionsTarget)
      .find('button[type="submit"]')
      .filter(':not([data-user-task-exclude-from-toggle])')
      .prop('disabled', !actionSelected || newSelectedCount === 0);
  };

  handleToggleChange = () => {
    this.updateToggleTarget();
  };

  handleCheckboxChange = (event) => {
    this.updateActionsTarget();
    this.toggleActions();
    this.updateCountTarget();
    this.updateCheckboxTarget(event.currentTarget);
  };

  handleTriggerClick(event) {
    event.preventDefault();
  }

  updateToggleTarget = () => {
    const $toggleTarget = $(this.hasToggleTarget && this.toggleTarget);
    $toggleTarget.closest('tr').toggleClass('selected', $toggleTarget.prop('checked') && !$toggleTarget.prop('indeterminate')); // prettier-ignore
  };

  updateActionsTarget = () => {
    $(this.hasActionsTarget && this.actionsTarget).toggle(this.selectedCount > 0);
  };

  updateCountTarget = () => {
    $(this.hasCountTarget && this.countTarget).text(this.selectedCount);
  };

  updateCheckboxTarget = (checkboxTarget) => {
    $(checkboxTarget).closest('tr').toggleClass('selected', checkboxTarget.checked); // prettier-ignore
  };
}
