import { Controller } from '@hotwired/stimulus';

/**
 * Like regular select2, but renders `data-count` (if provided) next to select options.
 */
export default class extends Controller {
  connect() {
    if (!this.isTouchDevice) {
      selectize(this.element);
    }
  }

  disconnect() {
    if (!this.isTouchDevice) {
      $(this.element).select2('destroy');
    }
  }

  get isTouchDevice() {
    return window.navigator.userAgent.match(/iPad|iPhone/i);
  }
}

function selectize(element) {
  const $select = $(element);

  if ($select.find('option[data-prepended]').length === 0 && $select.data('no-select-all') !== true) {
    if ($select.attr('multiple') === 'multiple') {
      $select.prepend($('<option data-prepended class="select-all" value="ALL">' + t('javascript.select2.select_all') + '</option>'));
    } else if (!$select.data('noblank')) {
      $select.prepend(
        $('<option data-prepended value="">' + ($select.data('prompt') || t('javascript.select2.placeholder')) + '</option>')
      );
    }
  }

  $select.select2({
    placeholder: $select.data('prompt') || t('javascript.select2.placeholder'),
    language: I18n_locale,
    allowClear: !($select.hasClass('required') || $select.hasClass('input--required')),
    width: '100%',
    maxWidth: '100%',
    templateSelection: function (item) {
      return $(item.element).data('select2-label') || item.text;
    },
  });

  if ($select.attr('multiple') === 'multiple') {
    $select.on('change', function () {
      if ($.inArray('ALL', $(this).val()) > -1) {
        $(this).find('option').prop('selected', false);
        $select.find('option[value!="ALL"]').prop('selected', true);
        return $select.trigger('change');
      }
    });
  }
}
