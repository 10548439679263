import { Controller } from '@hotwired/stimulus';

const standardOptions = {
  title: {
    text: null,
  },
  chart: {
    style: {
      fontFamily:
        'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji',
    },
  },
  credits: { enabled: false },
  plotOptions: {
    series: {
      stacking: 'normal',
    },
    column: {
      pointPadding: 0,
      borderWidth: 0,
      groupPadding: 0.1,
    },
  },
  tooltip: {
    shared: true,
    useHTML: true,
    outside: true,
    borderColor: '#e5e7eb',
    borderWidth: 1,
    borderRadius: 12,
    backgroundColor: '#ffffff',
    style: {},
  },
  xAxis: {
    type: 'linear',
    tickInterval: 1,
    labels: {
      maxStaggerLines: 1,
    },
  },
};

export default class BaseChartController extends Controller {
  static targets = ['container', 'data', 'tooltip'];

  connect() {
    this.$container = $(this.containerTarget);
    this.chartData = $.parseJSON($(this.dataTarget).html());
    this.$form = $(this.element).closest('form');

    if (this.data.has('type')) {
      this.type = this.data.get('type');
      this.render();
    }
  }

  render() {
    const baseChart = this;

    const renderOptions = {
      plotOptions: {
        series: {
          stacking: this.stacking,
          cursor: this.clickable ? 'pointer' : void 0,
          point: {
            events: {
              click: function () {
                return baseChart.handleClick(this);
              },
            },
          },
          events: {
            legendItemClick: function (event) {
              return event.preventDefault();
            },
          },
        },
      },
      chart: {
        renderTo: this.$container.attr('id'),
        type: this.chartType,
      },
      tooltip: {
        formatter: function () {
          return baseChart.formatTooltip(this.x);
        },
      },
      xAxis: {
        title: {
          text: this.chartData.options.xAxisTitle,
        },
        labels: {
          formatter: function () {
            return baseChart.formatXLabel(this.value);
          },
        },
      },
      yAxis: [
        {
          gridLineColor: '#e5e7eb',
          min: 0,
          floor: 0,
          title: {
            text: this.chartData.options.yAxisTitle,
          },
          allowDecimals: false,
        },
        this.hasPriceline
          ? {
              min: 0,
              floor: 0,
              opposite: true,
              title: {
                text: this.chartData.options.y1AxisTitle,
              },
              allowDecimals: true,
            }
          : void 0,
      ],
      series: this.activeSeries,
    };

    const highchartOptions = $.extend(true, {}, standardOptions, renderOptions);

    new Highcharts.Chart(highchartOptions);
  }

  get clickable() {
    return this.$form.length > 0;
  }

  formatXLabel(value) {
    return this.chartData.options.xLabels[value] || '';
  }

  formatTooltip(x) {
    return $(this.tooltipTargets).filter(`[data-range-id="${this.chartData.options.rangeIds[x]}"]`).html();
  }

  handleClick(point) {
    throw new Error('Not Implemented');
  }

  get stacking() {
    switch (this.type) {
      case 'stacked_column':
        return 'normal';
      default:
        return false;
    }
  }

  get chartType() {
    switch (this.type) {
      case 'stacked_column':
      case 'column':
        return 'column';
      default:
        return 'line';
    }
  }

  get activeSeries() {
    // Don't show priceline when type is line, will make a mess
    if (this.type === 'line') {
      return this.chartData.series.filter((s) => !s.priceline);
    } else {
      return this.chartData.series;
    }
  }

  get hasPriceline() {
    return this.activeSeries.filter((s) => s.priceline).length > 0;
  }
}
