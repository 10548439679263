import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const event = this.data.get('event');
    $(this.element).on(event, this.handleEvent);
  }

  disconnect() {
    $(this.element).off(event, this.handleEvent);
  }

  handleEvent = () => {
    const $loaderTarget = $(this.data.get('target') || this.element);
    App.attachLoader($loaderTarget, this.data.get('format') || 'large', true);
  };
}
