import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    $(this.handleDocumentReady);
  }

  disconnect() {
    $(this.element).datepicker('destroy');
  }

  handleDocumentReady = () => {
    $(this.element).datepicker({
      altField: this.data.get('alt'),
      altFormat: this.data.get('alt-format'),
      dateFormat: this.data.get('alt-format'),
      changeYear: this.data.has('change-year'),
      showWeek: this.data.has('show-week'),
      yearRange: this.data.get('year-range'),
      defaultDate: $(this.data.get('alt')).val(),
      onSelect: this.handleSelect,
    });
  };

  handleSelect = () => {
    if (this.data.has('autosubmit')) {
      $(this.element).parents('form').submit();
    }
  };
}
