import { Autocomplete as StimulusAutocomplete } from 'stimulus-autocomplete';

export default class Autocomplete extends StimulusAutocomplete {
  replaceResults(html) {
    super.replaceResults(html);
    this.maybeSelectFirstOption();
  }

  maybeSelectFirstOption() {
    if (!this.selectedOption && this.options.length > 0) {
      this.select(this.options[0]);
    }
  }
}
