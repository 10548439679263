const extend = require('./tailwind.extend.config');
const colors = require('./tailwind.colors.config');

module.exports = {
  content: ['app/views/**/*', 'app/components/**/*', 'app/helpers/**/*', 'app/javascript/**/*.{js,jsx}', 'config/locales/**/*.yml'],
  theme: {
    extend,
    colors,
    // See https://tailwindcss.com/docs/upgrading-to-v2#configure-your-font-size-scale-explicitly
    // px     | rem
    // -------|-----------
    // 24px   | 1.5rem
    // 20px   | 1.25rem
    // 18px   | 1.125rem
    // 16px   | 1rem
    // 14px   | 0.875rem
    // 13px   | 0.8125rem
    // 12px   | 0.75rem
    // 10px   | 0.625rem
    // 8px    | 0.5rem
    // 6px    | 0.375rem
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
      'heading-7xl': ['4.5rem', { fontWeight: '700', lineHeight: '1' }],
      'heading-6xl': ['3.75rem', { fontWeight: '700', lineHeight: '1' }],
      'heading-5xl': ['3rem', { fontWeight: '700', lineHeight: '1' }],
      'heading-4xl': ['2rem', { fontWeight: '700', lineHeight: '2.25rem' }],
      'heading-3xl': ['1.75rem', { fontWeight: '700', lineHeight: '2rem' }],
      'heading-2xl': ['1.5rem', { fontWeight: '700', lineHeight: '1.875rem' }],
      'heading-xl': ['1.25rem', { fontWeight: '700', lineHeight: '1.5rem' }],
      'heading-lg': ['1.125rem', { fontWeight: '700', lineHeight: '1.5rem' }],
      'heading-base': ['1rem', { fontWeight: '600', lineHeight: '1.25rem' }],
      'heading-sm': ['0.875rem', { fontWeight: '600', lineHeight: '1.25rem' }],
      'label-base': ['1rem', { fontWeight: '500', lineHeight: '1.5rem' }],
      'label-sm': ['0.875rem', { fontWeight: '500', lineHeight: '1.5rem' }],
      'label-xs': ['0.8125rem', { fontWeight: '500', lineHeight: '1.5rem' }],
      'label-2xs': ['0.75rem', { fontWeight: '500', lineHeight: '1rem' }],
      'body-base': ['1rem', { lineHeight: '1.5rem' }],
      'body-sm': ['0.875rem', { lineHeight: '1.5rem' }],
      'body-xs': ['0.8125rem', { lineHeight: '1.5rem' }],
      'body-2xs': ['0.75rem', { lineHeight: '1rem' }],
      'caps-base': ['0.6875rem', { fontWeight: '600', lineHeight: '1rem' }],
      'caps-sm': ['0.625rem', { fontWeight: '600', lineHeight: '1rem' }],
    },
  },
  plugins: [
    require('@tailwindcss/typography')({ className: 'markup' }),
    require('tailwind-underline-utils'),
    require('@tailwindcss/container-queries'),
  ],
};
