import { Controller } from '@hotwired/stimulus';
import { parseDecimal, formatAmount } from './channel_settlement_form_controller';

export default class extends Controller {
  static targets = ['open', 'price', 'difference'];

  handlePriceKeyup() {
    const open = parseDecimal($(this.openTarget).text());
    const price = parseDecimal($(this.priceTarget).val());

    $(this.differenceTarget).val(formatAmount(open - price, this.data.get('separator')));
  }
}
