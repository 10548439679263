import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['editor'];

  get locale() {
    return this.editorTarget.dataset.locale;
  }

  snippet(variableName) {
    let snippet = variableName;

    if (variableName === 'reservation.link') {
      snippet = "'" + t('javascript.auto_email.edit_reservation', this.locale) + "' | to_link";
    } else if (variableName === 'reservation.customer_portal_link') {
      snippet = "'" + t('javascript.auto_email.customer_portal', this.locale) + "' | customer_portal_link";
    }

    return '{{ ' + snippet + ' }}';
  }

  injectVariable(e) {
    const selectedVariable = e.target.value;

    if (selectedVariable === '') {
      return;
    }

    this.editorTarget.editor.recordUndoEntry('Insert Tag');
    this.editorTarget.editor.insertString(this.snippet(selectedVariable));

    e.target.value = '';
  }
}
