import { Controller } from '@hotwired/stimulus';

const FALLBACK_COLOR = '#ededed';

export default class extends Controller {
  static targets = ['colorInput', 'textInput', 'validSuffix', 'invalidSuffix'];

  connect() {
    this.colorInputTarget.addEventListener('input', this.handleColorInputChanged);
    this.textInputTarget.addEventListener('input', this.handleTextInputChanged);
    this.colorInputTarget.value = this.textInputTarget.value || FALLBACK_COLOR;
  }

  disconnect() {
    this.colorInputTarget.removeEventListener('input', this.handleColorInputChanged);
    this.textInputTarget.removeEventListener('input', this.handleTextInputChanged);
  }

  handleColorInputChanged = () => {
    this.textInputTarget.value = this.colorInputTarget.value;
    this.textInputTarget.dispatchEvent(new Event('input'));
    this.validSuffixTarget.hidden = false;
    this.invalidSuffixTarget.hidden = true;
  };

  handleTextInputChanged = () => {
    this.colorInputTarget.value = this.textInputTarget.value || FALLBACK_COLOR;
    this.textInputTarget.value = this.textInputTarget.value.toLowerCase();
    this.validSuffixTarget.hidden = !this.textInputTarget.checkValidity();
    this.invalidSuffixTarget.hidden = !this.validSuffixTarget.hidden;
  };
}
