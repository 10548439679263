import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash.debounce';

export default class extends Controller {
  static targets = ['form', 'field', 'content'];

  connect = () => {
    this.lastFormData = null;
    this.debouncedSubmit = debounce(this._submitForm, 500);
  };

  handleChange = () => {
    this.debouncedSubmit();
  };

  _submitForm = () => {
    const $form = $(this.formTarget);
    const formData = $form.serialize();

    if (this.lastFormData != formData) {
      $(this.formTarget).submit();

      for (const contentTarget of this.contentTargets) {
        App.attachLoader($(contentTarget), 'large', true);
      }
    }

    this.lastFormData = formData;
  };
}
