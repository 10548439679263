import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static classes = ['active'];

  toggleActive(isActive) {
    $(this.element).toggleClass(this.activeClass, isActive);
    $(this.element).attr('aria-selected', isActive.toString());
  }
}
