import { Controller } from '@hotwired/stimulus';

const ZOOM_LEVEL = 13;

export default class extends Controller {
  static targets = ['map', 'search', 'addressLabel', 'editLink'];

  connect() {
    // Render map
    this.map = new google.maps.Map(this.mapTarget, { center: { lat: 52.2393909, lng: 6.8366674 }, zoom: ZOOM_LEVEL });

    // Render autocomplete to search locations
    this.locationSearch = new google.maps.places.Autocomplete($(this.searchTarget).find('input').get(0));

    if ($(this.element.location_lookup_latitude).val() == '') {
      this.getUserLocation();
    } else {
      var pos = {
        lat: parseFloat($(this.element.location_lookup_latitude).val()),
        lng: parseFloat($(this.element.location_lookup_longitude).val()),
      };

      this.marker = new google.maps.Marker({ position: pos, map: this.map });
      this.map.setCenter(pos);
    }

    google.maps.event.addListener(this.locationSearch, 'place_changed', this.handleMapPlaceChanged);
  }

  disconnect() {
    google.maps.event.removeListener(this.locationSearch, 'place_changed', this.handleMapPlaceChanged);
  }

  getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        var pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        this.marker = new google.maps.Marker({ position: pos, map: this.map });
        this.map.setCenter(pos);
      });
    }
  };

  handleAddressKeydown(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  handleEdit(event) {
    $(this.searchTarget).show();
    $(this.searchTarget).find('input').first().focus();
    $(this.searchTarget).find('input').first().select();
    $(this.editLinkTarget).hide();
    event.preventDefault();
  }

  handleMapPlaceChanged = () => {
    var place = this.locationSearch.getPlace();
    var address = '';
    var city = '';

    if (this.getAddressComponent(place, 'route')) {
      address = this.getAddressComponent(place, 'route').long_name;
      if (this.getAddressComponent(place, 'street_number')) {
        address = address + ' ' + this.getAddressComponent(place, 'street_number').long_name;
      }
    } else {
      address = place.name;
    }

    if (this.getAddressComponent(place, 'locality')) {
      city = this.getAddressComponent(place, 'locality').long_name;
    } else {
      city = this.getAddressComponent(place, 'administrative_area_level_2').long_name;
    }

    var postalCode = this.getAddressComponent(place, 'postal_code')?.long_name;
    var country = this.getAddressComponent(place, 'country').long_name;
    var countryCode = this.getAddressComponent(place, 'country').short_name;
    var province = this.getAddressComponent(place, 'administrative_area_level_1').long_name;

    $(this.element.location_lookup_address).val(address);
    $(this.element.location_lookup_postalcode).val(postalCode);
    $(this.element.location_lookup_city_name).val(city);
    $(this.element.location_lookup_country_code).val(countryCode);
    $(this.element.location_lookup_province).val(province);

    if (this.addressLabelTarget) {
      var labelFormat = this.data.get('address-label-format');
      var text = labelFormat
        .replace('%{address}', address)
        .replace('%{city}', city)
        .replace('%{postalCode}', postalCode || '')
        .replace('%{country}', country);
      $(this.addressLabelTarget).html(text);
      $(this.addressLabelTarget).parent().show();
    }

    if (place.geometry) {
      this.map.setCenter(place.geometry.location);

      $(this.element.location_lookup_latitude).val(place.geometry.location.lat);
      $(this.element.location_lookup_longitude).val(place.geometry.location.lng);

      if (this.marker) {
        this.marker.setPosition(place.geometry.location);
      } else {
        this.marker = new google.maps.Marker({ position: place.geometry.location, map: this.map });
      }
    }

    $(this.searchTarget).hide();
    $(this.editLinkTarget).show();
  };

  getAddressComponent = (place, type) => {
    return place.address_components.find((element) => element.types.includes(type));
  };
}
