import { Controller } from '@hotwired/stimulus';

/**
 * Like regular select2, but fetches results (suggestions) via ajax call.
 */
export default class extends Controller {
  get $element() {
    return $(this.element);
  }

  connect() {
    this.$element.select2({
      query: this.performQuery.bind(this),
      placeholder: this.$element.attr('placeholder') || t('javascript.select2.ajax_placeholder'),
      allowClear: this.$element.data('required') === undefined,
      language: I18n_locale,
      data: [],
      delay: 250,
      templateResult: this.formatResult,
      templateSelection: this.formatResult,
    });
  }

  disconnect() {
    this.$element.select2().select2('destroy');
  }

  performQuery(params) {
    if (params.term && params.term.length > 2) {
      const onSuccess = (data) => params.callback(data);
      this.fetchResults(this.$element.data('url'), params, onSuccess);
    } else if (this.cachedData) {
      params.callback(this.cachedData);
    } else if (this.$element.data('suggestions-url')) {
      const onSuccess = (data) => {
        this.cachedData = data;
        params.callback(data);
      };
      this.fetchResults(this.$element.data('suggestions-url'), params, onSuccess);
    } else if (this.$element.find('option').length > 0) {
      const data = this.$element.find('option').map(function () {
        return { id: $(this).val(), text: $(this).text() };
      });

      this.cachedData = { results: $.makeArray(data) };
      params.callback(this.cachedData);
    } else {
      const initHint = t('javascript.select2.start_search');
      params.callback({ results: $.makeArray([{ id: null, text: initHint }]) });
    }
  }

  fetchResults(url, params, onSuccess) {
    $.getJSON(url, $.extend({ term: params.term, variant: 'select2' }, this.$element.data('params'))).done(onSuccess);
  }

  formatResult(result) {
    if (!result.id) {
      return $('<span class="text-muted"></span>').text(result.text).html();
    }

    var icon_html = null;
    if (result.data && result.data.icon_html) {
      // When the result is returned from the json endpoint of the server
      icon_html = result.data.icon_html;
    } else if (result.element && result.element.dataset && result.element.dataset.iconHtml) {
      // When the HTML node is parsed and an option is selected
      icon_html = result.element.dataset.iconHtml;
    }

    if (icon_html) {
      const result_html = $('<span class="ml-05"></span>').text(result.text).html();
      return $('<span>' + icon_html + result_html + '</span>');
    } else {
      return result.text;
    }
  }
}
