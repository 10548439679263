import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['passwordInput', 'showButton', 'hideButton'];

  connect() {
    $(this.showButtonTarget).on('click', (event) => this.showPassword(event));
    $(this.hideButtonTarget).on('click', (event) => this.hidePassword(event));
  }

  showPassword(event) {
    this.passwordInputTarget.type = 'text';
    this.showButtonTarget.classList.add('hidden');
    this.hideButtonTarget.classList.remove('hidden');
  }

  hidePassword(event) {
    this.passwordInputTarget.type = 'password';
    this.hideButtonTarget.classList.add('hidden');
    this.showButtonTarget.classList.remove('hidden');
  }
}
