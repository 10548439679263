import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['entries', 'template', 'addButton'];

  connect() {
    if (this.hasAddButtonTarget) {
      $(this.addButtonTarget).on('click', this.handleAddButtonClick);
    }
  }

  disconnect() {
    if (this.hasAddButtonTarget) {
      $(this.addButtonTarget).off('click', this.handleAddButtonClick);
    }
  }

  handleAddButtonClick = (event) => {
    const spawnedForms = this.spawn(parseInt($(event.currentTarget).data('count')) || 1);

    $(this.entriesTarget).trigger('nestedFormSpawn', spawnedForms);
    event.preventDefault();
  };

  spawn(count) {
    const spawnedForms = [];

    for (let i = 0; i < count; i++) {
      const templateHtml = $(this.templateTarget)
        .html()
        .replace(/new_nested_form/g, new Date().getTime() + i);
      const spawnedForm = $(templateHtml.trim());

      spawnedForm.appendTo(this.entriesTarget);
      spawnedForms.push(spawnedForm);
    }

    return spawnedForms;
  }
}
