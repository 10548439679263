import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['ownerFieldsWrapper', 'ownerSuggest'];

  ownerSuggestTargetConnected(element) {
    this.toggleOwnerFields(JSON.parse(element.dataset.suggestDefaultValue));
    element.addEventListener('change', this.handleOwnerSuggestChange);
  }

  ownerSuggestTargetDisconnected(element) {
    element.removeEventListener('change', this.handleOwnerSuggestChange);
  }

  handleOwnerSuggestChange = (event) => {
    this.toggleOwnerFields(event.detail.value);
  };

  toggleOwnerFields = (value) => {
    const ownerFieldsWrapper = value.length ? document.createElement('div') : document.createElement('template');

    ownerFieldsWrapper.setAttribute('data-agreements--form-target', 'ownerFieldsWrapper');
    ownerFieldsWrapper.innerHTML = this.ownerFieldsWrapperTarget.innerHTML;
    this.ownerFieldsWrapperTarget.parentNode.replaceChild(ownerFieldsWrapper, this.ownerFieldsWrapperTarget);
  };
}
