import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  get key() {
    return this.data.get('key');
  }

  toggleActive(isActive) {
    $(this.element).attr('hidden', !isActive);
    $(document).trigger('tabsPanel.toggleActive', [this.element, isActive]);
  }
}
