import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select', 'editor'];

  connect() {
    $(this.selectTarget).on('change', this.handleSelectChange);
  }

  disconnect() {
    $(this.selectTarget).off('change', this.handleSelectChange);
  }

  handleSelectChange = () => {
    if (!this.selectTarget.value) {
      return;
    }

    this.editorTarget.editor.recordUndoEntry(`Insert ${this.selectTarget.value} tag`);
    this.editorTarget.editor.insertString(`{{ ${this.selectTarget.value} }}`);

    this.selectTarget.value = '';
  };
}
