import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    if (!this.element.dataset.hotkeyKey || !this.modifier || !this.key) {
      return;
    }

    document.addEventListener('keydown', (e) => this.onKeyDown(e));
  }

  disconnect() {
    document.removeEventListener('keydown', (e) => this.onKeyDown(e));
  }

  onKeyDown(e) {
    if (e[`${this.modifier}Key`] && e.key.toLowerCase() === this.key) {
      if (document.activeElement !== this.element) {
        e.preventDefault();

        this.element.focus();
        this.element.select();
      }

      return false;
    }
  }

  get modifier() {
    const modKey = this.element.dataset.hotkeyKey.split('+')[0].toLowerCase();

    if (['alt', 'ctrl', 'shift', 'meta'].includes(modKey)) {
      return modKey;
    }
  }

  get key() {
    return this.element.dataset.hotkeyKey.split('+')[1].toLowerCase();
  }
}
