import { Controller } from '@hotwired/stimulus';

// Has to be exactly 'Text' to support IE11
const DATA_TRANSFER_TYPE = 'Text';

export default class extends Controller {
  static targets = ['counter', 'todo'];

  get count() {
    return parseInt($(this.hasCounterTarget && this.counterTarget).text());
  }

  get draggingClass() {
    return this.data.get('draggingClass');
  }

  get $fixedParent() {
    return this.data.has('fixed-parent') ? $(this.data.get('fixed-parent')) : null;
  }

  increaseCounter() {
    $(this.hasCounterTarget && this.counterTarget).text(this.count + 1);
  }

  decreaseCounter() {
    $(this.hasCounterTarget && this.counterTarget).text(this.count - 1);
  }

  handleTodoDragstart(event) {
    this.decreaseCounter();

    const $todo = $(event.target);
    const todoId = $todo.data('id');

    event.dataTransfer.setData(DATA_TRANSFER_TYPE, JSON.stringify({ todoId }));
    $todo.addClass(this.draggingClass);
  }

  handleTodoDragend(event) {
    // Dragging was cancelled
    if (event.dataTransfer.dropEffect === 'none') {
      this.increaseCounter();
    }

    $(event.target).removeClass(this.draggingClass);
  }

  handleDropzoneDragover(event) {
    // Prevent default to make droppable
    event.preventDefault();
  }

  handleDropzoneDrop(event) {
    event.preventDefault();
    this.increaseCounter();

    let todoId = null;

    try {
      const data = JSON.parse(event.dataTransfer.getData(DATA_TRANSFER_TYPE));
      todoId = data.todoId;
    } catch (error) {
      console.error('Failed to parse todo id from JSON data:', error);
      return;
    }

    const $todo = $(`#todo_${todoId}`);
    const $dropzone = $(event.currentTarget);

    this.placeInDropzone($dropzone, $todo, event.pageY);
    this.syncList(todoId);
  }

  placeInDropzone($dropzone, $todo, mouseY) {
    const firstTodoAfterMouseY = this.todoTargets.find((todoTarget) => {
      // Find the first todo element that is positioned below the mouse cursor
      // We use the offset of the todo element + half of its height to get the exact middle of the element
      const todoTargetOffset = $(todoTarget).offset().top + $(todoTarget).outerHeight() / 2;
      return todoTargetOffset > mouseY;
    });

    if (firstTodoAfterMouseY) {
      $(firstTodoAfterMouseY).before($todo);
    } else {
      $dropzone.append($todo);
    }

    $todo.effect('highlight', { color: HIGHLIGHT_COLOR }, 1000);
  }

  syncList(todoId) {
    const url = this.data.get('sync-url');
    const todo_ids = this.todoTargets.map((todoTarget) => $(todoTarget).data('id'));
    const executor_role_id = this.data.get('executor-role-id');
    const subject_todo_id = todoId;

    const data = { executor_role_id, todo_ids, subject_todo_id };

    return $.ajax({ method: 'PUT', url, data });
  }
}
