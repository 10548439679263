import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['selectSingleCostCheckbox', 'mergeSetSubmitButton'];

  connect() {
    $(this.selectSingleCostCheckboxTargets).on('change checkboxChange.selectAll', this.handleSelectSingleCostCheckboxChange);
  }

  disconnect() {
    $(this.selectSingleCostCheckboxTargets).off('change checkboxChange.selectAll', this.handleSelectSingleCostCheckboxChange);
  }

  // Event handlers

  handleSelectSingleCostCheckboxChange = (event) => {
    $(this.mergeSetSubmitButtonTarget).prop('disabled', $(this.selectSingleCostCheckboxTargets).filter(':checked').length < 2);
  };
}
