import { Controller } from '@hotwired/stimulus';
import { tailwindConfig } from '../lib/tailwind';

const HIGHLIGHT_COLOR = tailwindConfig.theme.colors.yellow['100'];
const HIGHLIGHT_DURATION = 1000;

export default class extends Controller {
  connect() {
    const originalBackgroundColor = $(this.element).css('backgroundColor');

    $(this.element)
      .animate({ duration: HIGHLIGHT_DURATION, backgroundColor: HIGHLIGHT_COLOR })
      .animate({ duration: HIGHLIGHT_DURATION, backgroundColor: originalBackgroundColor }, this.handleAnimateComplete);
  }

  handleAnimateComplete = () => {
    // Remove data-controller so that element won't be highlighted again when Turbo restores the page via cache
    const controllers = this.element
      .getAttribute('data-controller')
      .split(' ')
      .filter((c) => {
        c !== 'highlight';
      });

    if (controllers.length > 0) {
      this.element.setAttribute('data-controller', controllers.join(' '));
    } else {
      this.element.removeAttribute('data-controller');
    }
  };
}
