import { Controller } from '@hotwired/stimulus';
import React from 'react';
import ReactDOM from 'react-dom';

const DATE_FORMAT = 'YYYY-MM-DD';

export default class extends Controller {
  static targets = ['startDateInput', 'endDateInput', 'component'];

  initialize() {
    this.component = import(`../react_components/date_range_calendar.jsx`);
    this.mounted = false;
  }

  connect() {
    $(document).on('tabsPanel.toggleActive', this.handleTabsPanelToggleActive);
    this.startDateInputTarget.addEventListener('change', this.handleInputChange);
    this.endDateInputTarget.addEventListener('change', this.handleInputChange);

    this.mountComponentIfVisible();
  }

  disconnect() {
    $(document).off('tabsPanel.toggleActive', this.handleTabsPanelToggleActive);
    this.startDateInputTarget.removeEventListener('change', this.handleInputChange);
    this.endDateInputTarget.removeEventListener('change', this.handleInputChange);

    this.unmountComponent();
  }

  mountComponentIfVisible = () => {
    if ($(this.element).is(':visible')) {
      this.mountComponent();
    }
  };

  mountComponent = async () => {
    const DateRangeCalendar = await this.component;

    ReactDOM.render(
      <DateRangeCalendar.default
        initialStartDate={this.startDateInputTarget.value}
        initialEndDate={this.endDateInputTarget.value}
        minDate={this.data.get('min')}
        maxDate={this.data.get('max')}
        onChange={this.handleCalendarChange}
        onEndDateChange={this.handleCalendarEndDateChange}
      />,
      this.componentTarget
    );

    this.mounted = true;
  };

  unmountComponent = () => {
    ReactDOM.unmountComponentAtNode(this.componentTarget);
    this.mounted = false;
  };

  handleTabsPanelToggleActive = (e, tabsPanel, isActive) => {
    if (tabsPanel.contains(this.element) && isActive && !this.mounted) {
      this.mountComponentIfVisible();
    }
  };

  handleInputChange = () => {
    const startDateValue = this.startDateInputTarget.value;
    const endDateValue = this.endDateInputTarget.value;

    const startDate = isValidDate(startDateValue) ? moment(startDateValue, DATE_FORMAT) : null;
    const endDate = isValidDate(endDateValue) ? moment(endDateValue, DATE_FORMAT) : null;

    this.rerenderCalendar();
    this.dispatch('change', { detail: { startDate, endDate } });
  };

  handleCalendarChange = (startDate, endDate) => {
    this.startDateInputTarget.value = startDate ? startDate.format(DATE_FORMAT) : '';
    this.dispatch('change', { detail: { startDate, endDate } });
  };

  handleCalendarEndDateChange = (endDate) => {
    this.endDateInputTarget.value = endDate ? endDate.format(DATE_FORMAT) : '';
    this.dispatch('endDateChange', { detail: { endDate } });
  };

  setDates(startDate, endDate) {
    this.startDateInputTarget.value = startDate ? startDate.format(DATE_FORMAT) : '';
    this.endDateInputTarget.value = endDate ? endDate.format(DATE_FORMAT) : '';
    this.rerenderCalendar();
  }

  rerenderCalendar() {
    this.unmountComponent();
    this.mountComponent();
  }
}

function isValidDate(date) {
  return moment(date, DATE_FORMAT, true).isValid();
}
