import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  handleDocumentLoad() {
    if ('ontouchstart' in window) {
      document.getElementById('certificate_iframe').contentWindow.onload = function () {
        this.document.getElementsByTagName('img')[0].style.width = '100%';
      };
    }
  }
}
