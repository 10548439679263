import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['close'];

  connect() {
    $(this.closeTarget).on('click', this.handleCloseClick);
  }

  disconnect() {
    $(this.closeTarget).off('click', this.handleCloseClick);
  }

  handleCloseClick = () => {
    $(this.element).slideUp(150, () => $(this).remove());
  };
}
