import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    // Event handlers need to be setup here instead of via regular data-action=<...> attribute,
    // since that won't work in combination with ajax:* (jQuery) events. See https://github.com/stimulusjs/stimulus/issues/78
    $(this.element).on('ajax:success', this.handleAjaxSucces);
    $(this.element).on('ajax:error', this.handleAjaxError);
  }

  disconnect() {
    $(this.element).off('ajax:success', this.handleAjaxSucces);
    $(this.element).off('ajax:error', this.handleAjaxError);
  }

  handleAjaxSucces = () => {
    window.location.reload();
  };

  handleAjaxError = (_event, xhr) => {
    if (xhr.getResponseHeader('content-type').indexOf('text/html') !== -1) {
      const $formData = $('<div/>').html(xhr.responseText);
      $(this.element).parent('.content').html($formData.html()); // prettier-ignore
    }
  };
}
