import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { activeLocale: String, locales: Array };
  static targets = ['label', 'control', 'icon', 'text'];
  static outlets = ['localized-field-menu-item'];

  connect() {
    window.addEventListener('localized-field.select', this.handleSelect);
  }

  disconnect() {
    window.removeEventListener('localized-field.select', this.handleSelect);
  }

  labelTargetConnected(element) {
    this.labelFor = element.getAttribute('for');
    this.updateLabel(this.activeLocaleValue);
  }

  updateLabel = (locale) => {
    // Adds _<locale> to the label's for attribute
    this.labelTarget.setAttribute('for', `${this.labelFor}_${locale.replaceAll('-', '_').toLowerCase()}`);
  };

  handleSelect = (event) => {
    if (!this.localesValue.includes(event.detail.locale)) {
      return;
    }

    this.selectedLocale = event.detail.locale;
    this.iconTarget.src = event.detail.icon;
    this.textTarget.textContent = event.detail.text;

    this.controlTargets.forEach((controlTarget) => {
      const control = this.application.getControllerForElementAndIdentifier(controlTarget, 'localized-field-control');
      control.toggleActive(event.detail.locale);
    });

    this.updateLabel(event.detail.locale);
  };

  localizedFieldMenuItemOutletConnected(outlet) {
    // Prepare the menu item outlet to have the correct state when it is connected.
    // Only needed once a different locale is selected than the initial one.
    if (this.selectedLocale) {
      outlet.toggleActive(this.selectedLocale);
    }
  }
}
