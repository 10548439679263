import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'useSingleAccountRadioButton',
    'accountOverridesWrapper',
    'useInvoiceRuleRadioButton',
    'invoiceRuleConfigurationWrapper',
    'useSingleInvoiceRuleRadioButton',
    'invoiceRuleOverridesWrapper',
  ];

  connect() {
    $(this.useSingleAccountRadioButtonTargets).on('change', this.handleUseSingleAccountRadioButtonChange);
    $(this.useInvoiceRuleRadioButtonTargets).on('change', this.handleUseInvoiceRuleRadioButtonChange);
    $(this.useSingleInvoiceRuleRadioButtonTargets).on('change', this.handleUseSingleInvoiceRuleRadioButtonChange);

    $(this.useSingleAccountRadioButtonTargets).trigger('change');
    $(this.useInvoiceRuleRadioButtonTargets).trigger('change');
  }

  disconnect() {
    $(this.useSingleAccountRadioButtonTargets).off('change', this.handleUseSingleAccountRadioButtonChange);
    $(this.useInvoiceRuleRadioButtonTargets).off('change', this.handleUseInvoiceRuleRadioButtonChange);
    $(this.useSingleInvoiceRuleRadioButtonTargets).off('change', this.handleUseSingleInvoiceRuleRadioButtonChange);
  }

  // Event handlers

  handleUseSingleAccountRadioButtonChange = (event) => {
    const $radioButton = $(event.currentTarget);

    if ($radioButton.is(':checked')) {
      $(this.accountOverridesWrapperTarget).toggle($radioButton.val() != 'true');
    }
  };

  handleUseInvoiceRuleRadioButtonChange = (event) => {
    const $radioButton = $(event.currentTarget);

    if ($radioButton.is(':checked')) {
      $(this.invoiceRuleConfigurationWrapperTarget).toggle($radioButton.val() == 'true');

      $(this.useSingleInvoiceRuleRadioButtonTargets).trigger('change');
    }
  };

  handleUseSingleInvoiceRuleRadioButtonChange = (event) => {
    const $radioButton = $(event.currentTarget);

    if ($radioButton.is(':checked')) {
      $(this.invoiceRuleOverridesWrapperTarget).toggle($radioButton.val() != 'true');
    }
  };
}
