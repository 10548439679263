import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash.debounce';

const SCROLL_ANIMATION_DISTANCE = 400;
const SCROLL_ANIMATION_DURATION = 150;
const MENU_ACTIVE_CLASS = 'active';

export default class extends Controller {
  static targets = ['item', 'row'];

  get activeItemTarget() {
    return this.itemTargets.find((itemTarget) => $(itemTarget).hasClass(MENU_ACTIVE_CLASS));
  }

  connect() {
    this.setActiveMenuItem();

    if (this.activeItemTarget) {
      this.centerMenuItem($(this.activeItemTarget));
    }

    $(window).on('resize', this.handleWindowResize);
    $(window).triggerHandler('resize');
  }

  disconnect() {
    $(window).off('resize', this.handleWindowResize);
  }

  handleWindowResize = debounce(() => {
    const contentWidth = this.rowTarget.scrollWidth;
    const elementWidth = $(this.rowTarget).width();
    // Checked in: IE11, Chrome, Firefox, Safari, Safari (iOS), Chrome (Android)
    const isTouchDevice = 'ontouchstart' in document.documentElement;
    const showPaddles = elementWidth < contentWidth && !isTouchDevice;

    $(this.element).toggleClass(this.data.get('paddles-class'), showPaddles);
  }, 250);

  handleLeftPaddleClick() {
    $(this.rowTarget).animate({ scrollLeft: `-=${SCROLL_ANIMATION_DISTANCE}` }, SCROLL_ANIMATION_DURATION);
  }

  handleRightPaddleClick() {
    $(this.rowTarget).animate({ scrollLeft: `+=${SCROLL_ANIMATION_DISTANCE}` }, SCROLL_ANIMATION_DURATION);
  }

  setActiveMenuItem() {
    if (App.navigation) {
      for (let i = 0; i < this.itemTargets.length; i++) {
        const $menuItem = $(this.itemTargets[i]);
        if ($menuItem.data('navigation') === App.navigation) {
          const subnavigation = $menuItem.data('subnavigation');
          if (!subnavigation || subnavigation === App.subnavigation) {
            $menuItem.addClass(MENU_ACTIVE_CLASS);
          }
        }
      }
    }
  }

  centerMenuItem($menuItem) {
    const itemOffsetLeft = $menuItem.offset().left;
    const itemWidth = $menuItem.width();
    const viewportWidth = $(window).width();
    const targetOffsetLeft = itemOffsetLeft - viewportWidth / 2 + itemWidth / 2;

    $(this.rowTarget).scrollLeft(targetOffsetLeft);
  }
}
