import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    document.addEventListener('turbo:submit-start', this.handleSubmitStart);
  }

  disconnect() {
    document.removeEventListener('turbo:submit-start', this.handleSubmitStart);
  }

  handleSubmitStart = () => {
    $(this.element).css('opacity', 0.5);
  };
}
