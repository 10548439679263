import BaseChartController from './base_chart_controller';

export default class extends BaseChartController {
  static targets = [...super.targets, 'rangeIdInput', 'groupIdsInput', 'labelInput', 'colorInput', 'bookedBeforeInput'];

  connect() {
    this.$rangeIdInput = $(this.rangeIdInputTarget);
    this.$groupIdsInput = $(this.groupIdsInputTarget);
    this.$labelInput = $(this.labelInputTarget);
    this.$colorInput = $(this.colorInputTarget);
    this.$bookedBeforeInput = $(this.bookedBeforeInputTarget);
    super.connect();
  }

  handleClick(point) {
    if (!point.series.userOptions.priceline) {
      const userOptions = point.series.userOptions;

      this.$rangeIdInput.val(point.rangeId);
      this.$groupIdsInput.val(userOptions.groupIds);
      this.$labelInput.val(userOptions.name);
      this.$colorInput.val(userOptions.color);
      this.$bookedBeforeInput.val(userOptions.bookedBefore);

      return this.$form.submit();
    }
  }
}
