import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'amount',
    'amountPercentage',
    'deadline',
    'restAmount',
    'restAmountPercentage',
    'restDeadline',
    'restDeadlineOffset',
    'paymentScheduleRule',
  ];

  get total() {
    return parseFloat(this.data.get('total')) || 0;
  }

  get hasInitialInstalment() {
    return this.data.has('has-initial-instalment');
  }

  get paymentScheduleRuleControllers() {
    return this.paymentScheduleRuleTargets.map((paymentScheduleRuleTarget) =>
      this.application.getControllerForElementAndIdentifier(paymentScheduleRuleTarget, 'payment-schedule-rule')
    );
  }

  connect() {
    $(this.element).on('change', this.targets.getSelectorForTargetName('amount'), this.handleAmountChange);
    $(this.element).on('change', this.targets.getSelectorForTargetName('amountPercentage'), this.handleAmountPercentageChange);
    $(this.element).on('nestedFormSpawn', this.handleNestedFormSpawn);
    $(this.element).on('nestedFormRemove', this.handleNestedFormRemove);

    window.requestAnimationFrame(() => this.setComputedFields());
  }

  disconnect() {
    $(this.element).off('change', this.targets.getSelectorForTargetName('amount'), this.handleAmountChange);
    $(this.element).off('change', this.targets.getSelectorForTargetName('amountPercentage'), this.handleAmountPercentageChange);
    $(this.element).off('nestedFormSpawn', this.handleNestedFormSpawn);
    $(this.element).off('nestedFormRemove', this.handleNestedFormRemove);
  }

  setComputedFields() {
    const boundTotal = this.total;
    const assignedTotal = this.amountTargets.reduce((sum, amountTarget) => sum + parseFloat($(amountTarget).val()), 0);

    // Set rest amount
    const restAmount = boundTotal - assignedTotal;
    $(this.restAmountTarget).val(restAmount.toFixed(2));

    // Set rest amount percentage
    const restAmountPercentage = ((restAmount === 0 ? 0 : restAmount / boundTotal) * 100).toFixed(2);
    $(this.restAmountPercentageTarget).val(restAmountPercentage);

    // Set computed fields of payment schedule rules
    this.paymentScheduleRuleControllers.forEach((paymentScheduleRuleController) => paymentScheduleRuleController.setComputedFields());
  }

  splitAmounts() {
    let totalToSplit = this.total;
    let divideBy = this.amountTargets.length + 1;

    if (this.hasInitialInstalment) {
      // Remove first instalment from being divided
      totalToSplit -= parseFloat($(this.amountTargets[0]).val());
      divideBy -= 1;
    }

    const splitAmount = (totalToSplit / divideBy).toFixed(2);
    const leftOver = totalToSplit - splitAmount * divideBy;

    $(this.amountTargets).each(function (index) {
      // Skip first instalment
      if (this.hasInitialInstalment && index === 0) {
        return true;
      }

      let amount = splitAmount;

      // Assign the left over to the last instalment
      if (index === divideBy) {
        amount += leftOver;
      }

      $(this).val(amount);
    });
  }

  splitDeadlines() {
    const deadlines = [...this.deadlineTargets, this.restDeadlineTarget].map((deadlineTarget) =>
      moment($(deadlineTarget).val(), 'DD-MM-YYYY')
    );

    const startDate = deadlines[0];
    const endDate = deadlines[deadlines.length - 1];
    const days = endDate.diff(startDate, 'days');
    const step = Math.ceil(days / (deadlines.length - 1));

    $([...this.deadlineTargets, this.restDeadlineTarget]).each(function (index, element) {
      // Dont change start- or end-date
      if (index === 0 || index === deadlines.length - 1) {
        return true;
      }

      const newDate = startDate.clone().add(index * step, 'day');
      $(element).val(newDate.format('DD-MM-YYYY'));
    });
  }

  handleAmountChange = () => {
    this.setComputedFields();
  };

  handleAmountPercentageChange = () => {
    this.setComputedFields();
  };

  handleNestedFormSpawn = () => {
    this.splitAmounts();
    this.splitDeadlines();
    window.requestAnimationFrame(() => this.setComputedFields());
  };

  handleNestedFormRemove = () => {
    this.splitAmounts();
    this.splitDeadlines();
    this.setComputedFields();
    window.requestAnimationFrame(() => this.setComputedFields());
  };
}
