import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'quantityTypeSelect',
    'priceWrappersContainer',
    'priceHeader',
    'priceWrapper',
    'useSinglePriceRadioButton',
    'pricingRadioButtonsWrapper',
    'pricingRadioButton',
    'priceOverridesWrapperContainer',
    'priceOverridesPerRentableTypeWrapperTemplate',
    'priceOverridesPerChannelWrapperTemplate',
    'priceOverridesPerRentableTypeAndChannelWrapperTemplate',
    'priceOverrideHeader',
    'priceOverrideWrapper',
    'priceOverrideHeaderTemplate',
    'priceOverrideWrapperTemplate',
  ];

  connect() {
    this.$quantityTypeSelectTarget = $(this.quantityTypeSelectTarget);
    this.$priceWrappersContainerTarget = $(this.priceWrappersContainerTarget);
    this.$useSinglePriceRadioButtonTargets = $(this.useSinglePriceRadioButtonTargets);
    this.$pricingRadioButtonTargets = $(this.pricingRadioButtonTargets);

    // prettier-ignore
    this.$priceOverridesPerRentableTypeWrapper =
      $(this.priceOverridesPerRentableTypeWrapperTemplateTarget.content.firstElementChild);

    // prettier-ignore
    this.$priceOverridesPerChannelWrapper =
      $(this.priceOverridesPerChannelWrapperTemplateTarget.content.firstElementChild);

    // prettier-ignore
    this.$priceOverridesPerRentableTypeAndChannelWrapper =
      $(this.priceOverridesPerRentableTypeAndChannelWrapperTemplateTarget.content.firstElementChild);

    this.$priceOverridesWrappers = [
      this.$priceOverridesPerRentableTypeWrapper,
      this.$priceOverridesPerChannelWrapper,
      this.$priceOverridesPerRentableTypeAndChannelWrapper,
    ];

    this.priceOverrideHeaderTemplate = this.priceOverrideHeaderTemplateTarget.content.firstElementChild.outerHTML;
    this.priceOverrideWrapperTemplate = this.priceOverrideWrapperTemplateTarget.content.firstElementChild.outerHTML;

    this.$currentPriceOverridesWrapper = null;

    this.$quantityTypeSelectTarget.on('select2:select', this.handleQuantityTypeSelectChange);

    this.$priceWrappersContainerTarget.on(
      'change',
      this.targets.getSelectorForTargetName('activeFromInputField'),
      this.handleActiveFromInputFieldChange
    );

    this.$priceWrappersContainerTarget.on(
      'change',
      this.targets.getSelectorForTargetName('defaultAmountInputField'),
      this.handleDefaultAmountInputFieldChange
    );

    this.$priceWrappersContainerTarget.on('nestedFormSpawn', this.handlePriceWrapperAdded);
    this.$priceWrappersContainerTarget.on('nestedFormRemove', this.handlePriceWrapperRemoved);
    this.$useSinglePriceRadioButtonTargets.on('change', this.handleUseSinglePriceRadioButtonChange);
    this.$pricingRadioButtonTargets.on('change', this.handlePricingRadioButtonChange);

    this.$quantityTypeSelectTarget.trigger('select2:select');
    this.$useSinglePriceRadioButtonTargets.trigger('change');
  }

  disconnect() {
    this.$quantityTypeSelectTarget.off('select2:select', this.handleQuantityTypeSelectChange);

    this.$priceWrappersContainerTarget.off(
      'change',
      this.targets.getSelectorForTargetName('activeFromInputField'),
      this.handleActiveFromInputFieldChange
    );

    this.$priceWrappersContainerTarget.off(
      'change',
      this.targets.getSelectorForTargetName('defaultAmountInputField'),
      this.handleAmountInputFieldChange
    );

    this.$priceWrappersContainerTarget.off('nestedFormSpawn', this.handlePriceWrapperAdded);
    this.$priceWrappersContainerTarget.off('nestedFormRemove', this.handlePriceWrapperRemoved);
    this.$useSinglePriceRadioButtonTargets.off('change', this.handleUseSinglePriceRadioButtonChange);
    this.$pricingRadioButtonTargets.off('change', this.handlePricingRadioButtonChange);
  }

  // Event handlers

  handleUseSinglePriceRadioButtonChange = (event) => {
    const $radioButton = $(event.currentTarget);

    if ($radioButton.is(':checked')) {
      this.useSinglePrice = $radioButton.val() == 'true';

      $(this.pricingRadioButtonsWrapperTarget).toggle(!this.useSinglePrice);

      if (this.useSinglePrice) {
        this.detachCurrentPriceOverridesWrapper();
      } else {
        this.$pricingRadioButtonTargets.trigger('change');
      }
    }
  };

  handlePricingRadioButtonChange = (event) => {
    const $radioButton = $(event.currentTarget);

    if ($radioButton.is(':checked')) {
      switch ($radioButton.val()) {
        case 'price_per_rentable_type':
          this.showPriceOverridesWrapper(this.$priceOverridesPerRentableTypeWrapper);
          break;
        case 'price_per_channel':
          this.showPriceOverridesWrapper(this.$priceOverridesPerChannelWrapper);
          break;
        case 'price_per_rentable_type_and_channel':
          this.showPriceOverridesWrapper(this.$priceOverridesPerRentableTypeAndChannelWrapper);
          break;
      }
    }
  };

  handleQuantityTypeSelectChange = () => {
    const forPercentage = this.percentualQuantityTypeSelected();

    $(this.priceHeaderTarget).children('.price-header').toggle(!forPercentage);

    $(this.priceHeaderTarget).children('.percentage-header').toggle(forPercentage);

    this.updateAmountAffixes(this.priceWrapperTargets, forPercentage);
    this.updateAmountAffixes(this.priceOverrideWrapperTargets, forPercentage);
  };

  handlePriceWrapperAdded = (_, $priceWrapper) => {
    const activeFrom = $priceWrapper.find(this.targets.getSelectorForTargetName('activeFromInputField')).val();
    const forPercentage = this.percentualQuantityTypeSelected();
    const priceIndex = this.getPriceIndex($priceWrapper);

    for (const $priceOverridesWrapper of this.$priceOverridesWrappers) {
      this.updateChannelHeadersIfPresent($priceOverridesWrapper, 1);

      const $priceOverrideHeadersRow = $priceOverridesWrapper.find('thead tr').last();
      // prettier-ignore
      const maximumColumnIndex = $priceOverrideHeadersRow.children().last().data('columnIndex');

      for (let columnIndex = 0; columnIndex <= maximumColumnIndex; columnIndex++) {
        const $targetHeader = $priceOverrideHeadersRow.children(`[data-column-index='${columnIndex}']`).last();

        const requiredSubstitutionsForPriceOverrideHeader = {
          price_index_placeholder: priceIndex,
          column_index_placeholder: columnIndex,
          active_from_placeholder: activeFrom,
        };

        // prettier-ignore
        const $newPriceOverrideHeader =
          $(this.replaceAll(this.priceOverrideHeaderTemplate, requiredSubstitutionsForPriceOverrideHeader));

        $newPriceOverrideHeader.insertAfter($targetHeader);

        // prettier-ignore
        $priceOverridesWrapper.find('tbody tr').toArray().forEach((priceOverrideWrappersRow, rowIndex) => {
          const $priceOverrideWrappersRow = $(priceOverrideWrappersRow);
          const $targetCell = $priceOverrideWrappersRow.children(`[data-column-index='${columnIndex}']`).last();
          const $rentableTypeIdInputField = $targetCell.find(this.targets.getSelectorForTargetName('rentableTypeIdInputField'));
          const $channelIdInputField = $targetCell.find(this.targets.getSelectorForTargetName('channelIdInputField'));

          const requiredSubstitutionsForPriceOverrideWrapper = {
            price_index_placeholder: priceIndex,
            row_index_placeholder: rowIndex,
            column_index_placeholder: columnIndex,
            rentable_type_id_placeholder: $rentableTypeIdInputField.val(),
            channel_id_placeholder: $channelIdInputField.val(),
          };

          // prettier-ignore
          const $newPriceOverrideWrapper =
            $(this.replaceAll(this.priceOverrideWrapperTemplate, requiredSubstitutionsForPriceOverrideWrapper));

          if ($rentableTypeIdInputField.length == 0) {
            $newPriceOverrideWrapper.find(this.targets.getSelectorForTargetName('rentableTypeIdInputField')).parent().remove();
          } else if ($channelIdInputField.length == 0) {
            $newPriceOverrideWrapper.find(this.targets.getSelectorForTargetName('channelIdInputField')).parent().remove();
          }

          $newPriceOverrideWrapper.insertAfter($targetCell);
        });
      }
    }

    this.updateAmountAffixes($priceWrapper, forPercentage);
    this.updateAmountAffixes(this.priceOverrideWrapperTargets, forPercentage);
  };

  handlePriceWrapperRemoved = (_, priceWrapper) => {
    const priceIndex = this.getPriceIndex($(priceWrapper));

    for (const $priceOverridesWrapper of this.$priceOverridesWrappers) {
      this.updateChannelHeadersIfPresent($priceOverridesWrapper, -1);

      const targets = ['priceOverrideHeader', 'priceOverrideWrapper'];

      for (const target of targets) {
        const selector = this.getSelectorForTargetNameWithPriceIndexFilter(target, priceIndex);

        $priceOverridesWrapper.find(selector).remove();
      }
    }
  };

  handleDefaultAmountInputFieldChange = (event) => {
    const $amountInputField = $(event.currentTarget);
    const amount = new Number($amountInputField.val()).toLocaleString(App.locale);
    const priceIndex = this.getPriceIndex($amountInputField.closest(this.targets.getSelectorForTargetName('priceWrapper')));

    for (const $priceOverridesWrapper of this.$priceOverridesWrappers) {
      const selector = this.getSelectorForTargetNameWithPriceIndexFilter('priceOverrideWrapper', priceIndex);
      const $priceOverrideWrappers = $priceOverridesWrapper.find(selector);

      for (const priceOverrideWrapper of $priceOverrideWrappers) {
        // prettier-ignore
        $(priceOverrideWrapper).find(this.targets.getSelectorForTargetName('amountInputField')).attr('placeholder', amount);
      }
    }
  };

  handleActiveFromInputFieldChange = (event) => {
    const $activeFromInputField = $(event.currentTarget);
    const activeFrom = $activeFromInputField.val();
    const priceIndex = this.getPriceIndex($activeFromInputField.closest(this.targets.getSelectorForTargetName('priceWrapper')));

    for (const $priceOverridesWrapper of this.$priceOverridesWrappers) {
      const selector = this.getSelectorForTargetNameWithPriceIndexFilter('priceOverrideHeader', priceIndex);
      const $priceOverrideHeaders = $priceOverridesWrapper.find(selector);

      for (const priceOverrideHeader of $priceOverrideHeaders) {
        $(priceOverrideHeader).text(activeFrom);
      }
    }
  };

  // Utils

  showPriceOverridesWrapper($priceOverridesWrapper) {
    if (this.$currentPriceOverridesWrapper != $priceOverridesWrapper) {
      this.detachCurrentPriceOverridesWrapper();
      $priceOverridesWrapper.appendTo($(this.priceOverridesWrapperContainerTarget));
      this.updateAmountAffixes(this.priceOverrideWrapperTargets, this.percentualQuantityTypeSelected());
      this.$currentPriceOverridesWrapper = $priceOverridesWrapper;
    }
  }

  detachCurrentPriceOverridesWrapper() {
    if (this.$currentPriceOverridesWrapper) {
      this.$currentPriceOverridesWrapper.detach();
      this.$currentPriceOverridesWrapper = null;
    }
  }

  updateChannelHeadersIfPresent($priceOverridesWrapper, colspanChange) {
    const $channelHeaders = $priceOverridesWrapper.find(this.targets.getSelectorForTargetName('channelHeader'));

    for (const channelHeader of $channelHeaders) {
      const $channelHeader = $(channelHeader);

      $channelHeader.attr('colspan', parseInt($channelHeader.attr('colspan')) + colspanChange);
    }
  }

  updateAmountAffixes(elements, forPercentage) {
    for (const element of elements) {
      const $element = $(element);

      $element.find('span.price-prefix').toggle(!forPercentage);
      $element.find('span.percentage-suffix').toggle(forPercentage);
    }
  }

  getPriceIndex($priceWrapper) {
    // prettier-ignore
    return $priceWrapper.find("input[name='price_index']").val();
  }

  getSelectorForTargetNameWithPriceIndexFilter(targetName, priceIndex) {
    return this.targets.getSelectorForTargetName(targetName) + `[data-price-index='${priceIndex}']`;
  }

  percentualQuantityTypeSelected() {
    return this.$quantityTypeSelectTarget.val().startsWith('percentage');
  }

  replaceAll(string, requiredSubstitutions) {
    const regExp = new RegExp(Object.keys(requiredSubstitutions).join('|'), 'g');

    // prettier-ignore
    return string.replace(regExp, match => {
      return requiredSubstitutions[match];
    }).trim();
  }
}
