import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['adminPermissionCheckbox'];

  connect() {
    $(this.adminPermissionCheckboxTargets).on('change', this.applyPermissionDependencies);
  }

  disconnect() {
    $(this.adminPermissionCheckboxTargets).off('change', this.applyPermissionDependencies);
  }

  applyPermissionDependencies = (event) => {
    this.updateDependentCheckboxes($(event.currentTarget), $(this.adminPermissionCheckboxTargets));
  };

  updateDependentCheckboxes($changedCheckbox, $checkboxes) {
    if ($changedCheckbox.is(':checked')) {
      const changedCheckboxPermissionDependencies = $changedCheckbox.data('permissionDependencies');

      for (const checkbox of $checkboxes) {
        const $checkbox = $(checkbox);

        if ($checkbox.is(':disabled') || $checkbox.is($changedCheckbox)) {
          continue;
        } else if (changedCheckboxPermissionDependencies.includes($checkbox.data('permission'))) {
          $checkbox.prop('checked', true);
        }
      }
    } else {
      const changedCheckboxPermission = $changedCheckbox.data('permission');

      for (const checkbox of $checkboxes) {
        const $checkbox = $(checkbox);

        if ($checkbox.is(':disabled') || $checkbox.is($changedCheckbox)) {
          continue;
        } else if ($checkbox.data('permissionDependencies').includes(changedCheckboxPermission)) {
          $checkbox.prop('checked', false);
        }
      }
    }
  }
}
