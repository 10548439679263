import React from 'react';
import moment from '../lib/moment';

export const PrevIcon = () => (
  <button className="button p-0 min-w-[30px] h-[30px]" type="button" style={{ position: 'absolute', left: '22px', top: '20px' }}>
    <div className="button__content">
      <i className="fa fa-chevron-left text-xs" />
    </div>
  </button>
);

export const NextIcon = () => (
  <button className="button p-0 min-w-[30px] h-[30px]" type="button" style={{ position: 'absolute', right: '22px', top: '20px' }}>
    <div className="button__content">
      <i className="fa fa-chevron-right text-xs" />
    </div>
  </button>
);

export function renderMonthElement({ month, onMonthSelect, onYearSelect }) {
  // prettier-ignore
  const years = Array(10).fill().map((_, index) => month.year() - 5 + index);

  return (
    <div className="flex justify-center items-stretch space-x-2 mx-11 pb-px h-8">
      <select className="select select--small text-xs" value={month.month()} onChange={(e) => onMonthSelect(month, e.target.value)}>
        {moment.months().map((label, value) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </select>
      <select className="select select--small text-xs" value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
}
