import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'kindSelect',
    'resellerWrapper',
    'tourOperatorWrapper',
    'useCostsInvoicedToRadioButton',
    'costsInvoicedToWrapper',
    'costInvoicingConfigurationsWrapper',
  ];

  connect() {
    $(this.kindSelectTarget).on('change', this.handleKindSelectChange);
    $(this.useCostsInvoicedToRadioButtonTargets).on('change', this.handleUseCostsInvoicedToRadioButtonChange);

    $(this.kindSelectTarget).trigger('change');
    $(this.useCostsInvoicedToRadioButtonTargets).trigger('change');
  }

  disconnect() {
    $(this.kindSelectTarget).off('change', this.handleKindSelectChange);
    $(this.useCostsInvoicedToRadioButtonTargets).off('change', this.handleUseCostsInvoicedToRadioButtonChange);
  }

  // Event handlers

  handleKindSelectChange = () => {
    const isReseller = $(this.kindSelectTarget).val() == 'reseller';

    $(this.resellerWrapperTargets).toggle(isReseller);
    $(this.tourOperatorWrapperTargets).toggle(!isReseller);
  };

  handleUseCostsInvoicedToRadioButtonChange = (event) => {
    const $radioButton = $(event.currentTarget);

    if ($radioButton.is(':checked')) {
      const useCostsInvoicedTo = $radioButton.val() == 'true';

      $(this.costsInvoicedToWrapperTarget).toggle(useCostsInvoicedTo);
      $(this.costInvoicingConfigurationsWrapperTarget).toggle(!useCostsInvoicedTo);
    }
  };
}
