import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger', 'form'];

  handleTriggerClick() {
    $(this.triggerTarget).hide();
    $(this.formTarget).show();
  }

  handleCancelClick() {
    $(this.formTarget).hide();
    $(this.triggerTarget).show();
  }
}
