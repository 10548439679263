import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['icon', 'text', 'check'];
  static classes = ['active'];
  static values = { locale: String };

  connect() {
    this.element.addEventListener('click', this.handleClick);
  }

  disconnect() {
    this.element.removeEventListener('click', this.handleClick);
  }

  handleClick = () => {
    const detail = {
      locale: this.localeValue,
      icon: this.iconTarget.src,
      text: this.textTarget.textContent,
    };

    window.dispatchEvent(new CustomEvent('localized-field.select', { detail }));
    this.element.closest('[data-tippy-root]')._tippy.hide();
  };

  toggleActive(selectedLocale) {
    const isActive = this.localeValue === selectedLocale;

    this.element.classList.toggle(this.activeClass, isActive);

    // Use visibility instead of display so that the correct width is used for the popover.
    this.checkTarget.style.visibility = isActive ? 'visible' : 'hidden';
  }
}
