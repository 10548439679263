import { Controller } from '@hotwired/stimulus';

function preventSubmit(event) {
  // Prevent submitting form on ENTER
  if (event.keyCode === 13) {
    event.preventDefault();
  }
}

export default class extends Controller {
  static targets = ['amount', 'amountPercentage', 'deadline', 'deadlineOffset'];

  get total() {
    return parseFloat(this.data.get('total')) || 0;
  }

  connect() {
    $(this.amountTarget).on('keydown', preventSubmit);
    $(this.amountPercentageTarget).on('keydown', preventSubmit);
    $(this.deadlineTarget).on('keydown', preventSubmit);
    $(this.amountPercentageTarget).on('change', this.handleAmountPercentageChange);

    $(this.amountTarget).on('change', this.handleAmountChange);
    $(this.amountPercentageTarget).on('change', this.handleAmountPercentageChange);
    $(this.deadlineTarget).on('change', this.handleDeadlineChange);

    this.setComputedFields();
  }

  disconnect() {
    $(this.amountTarget).off('keydown', preventSubmit);
    $(this.amountPercentageTarget).off('keydown', preventSubmit);
    $(this.deadlineTarget).off('keydown', preventSubmit);

    $(this.amountTarget).off('change', this.handleAmountChange);
    $(this.amountPercentageTarget).off('change', this.handleAmountPercentageChange);
    $(this.deadlineTarget).off('change', this.handleDeadlineChange);
  }

  setComputedFields() {
    const amount = parseFloat($(this.amountTarget).val()) || 0;
    const amountPercentage = ((amount === 0 ? 0 : amount / this.total) * 100).toFixed(2);
    $(this.amountPercentageTarget).val(amountPercentage);

    const deadlineOffset = days_offset_in_words(moment().startOf('day'), moment($(this.deadlineTarget).val(), 'DD-MM-YYYY'));
    $(this.deadlineOffsetTarget).text(deadlineOffset);
  }

  handleAmountChange = () => {
    this.setComputedFields();
  };

  handleAmountPercentageChange = () => {
    const newAmount = (parseFloat($(event.target).val()) / 100.0) * this.total;
    $(this.amountTarget).val(newAmount.toFixed(2));

    this.setComputedFields();
  };

  handleDeadlineChange = () => {
    this.setComputedFields();
  };
}
