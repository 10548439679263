import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'appliesToAllRadioButton',
    'sourcePicker',
    'quickSelectCheckBox',
    'selectAllCheckBox',
    'sourceCheckBox',
    'selectionCount',
  ];

  connect() {
    $(this.appliesToAllRadioButtonTargets).on('change', this.handleAppliesToAllRadioButtonChange);
    $(this.quickSelectCheckBoxTargets).on('change', this.handleQuickSelectCheckBoxChange);
    $(this.hasSelectAllCheckBoxTarget && this.selectAllCheckBoxTarget).on('click', this.handleSelectAllCheckBoxClick);
    $(this.sourceCheckBoxTargets).on('change', this.handleSourceCheckBoxChange);

    $(this.appliesToAllRadioButtonTargets).trigger('change');
    this.updateQuickSelectCheckBoxes();
    this.updateSelectionCount();
  }

  disconnect() {
    $(this.appliesToAllRadioButtonTargets).off('change', this.handleAppliesToAllRadioButtonChange);
    $(this.quickSelectCheckBoxTargets).off('change', this.handleQuickSelectCheckBoxChange);
    $(this.hasSelectAllCheckBoxTarget && this.selectAllCheckBoxTarget).off('click', this.handleSelectAllCheckBoxClick);
    $(this.sourceCheckBoxTargets).off('change', this.handleSourceCheckBoxChange);
  }

  handleAppliesToAllRadioButtonChange = (event) => {
    const $radioButton = $(event.currentTarget);

    if ($radioButton.is(':checked')) {
      $(this.sourcePickerTarget).toggle($radioButton.val() != 'true');
    }
  };

  handleQuickSelectCheckBoxChange = (event) => {
    const $quickSelectCheckBox = $(event.currentTarget);

    for (const checkbox of $(this.sourceCheckBoxTargets)) {
      const $checkbox = $(checkbox);

      if (this.checkboxSelectedByQuickSelectCheckBox($checkbox, $quickSelectCheckBox)) {
        $checkbox.prop('checked', $quickSelectCheckBox.is(':checked'));
      }
    }

    this.updateQuickSelectCheckBoxes();
    this.updateSelectionCount();
  };

  handleSelectAllCheckBoxClick = () => {
    const isChecked = $(this.hasSelectAllCheckBoxTarget && this.selectAllCheckBoxTarget).is(':checked');

    for (const checkbox of $(this.sourceCheckBoxTargets)) {
      $(checkbox).prop('checked', isChecked);
    }

    // Quicker than calling updateQuickSelectCheckBoxes()
    for (const quickSelectCheckBox of $(this.quickSelectCheckBoxTargets)) {
      $(quickSelectCheckBox).prop('checked', isChecked);
    }

    this.updateSelectionCount();
  };

  handleSourceCheckBoxChange = () => {
    this.updateQuickSelectCheckBoxes();
    this.updateSelectionCount();
  };

  updateSelectionCount() {
    $(this.selectionCountTarget).text($(this.sourceCheckBoxTargets).filter(':checked').length);
  }

  updateQuickSelectCheckBoxes() {
    let allCheckBoxesAreChecked = true;
    const quickSelectCheckBoxesToDisable = [];

    for (const checkbox of $(this.sourceCheckBoxTargets)) {
      const $checkbox = $(checkbox);

      if (!$checkbox.is(':checked')) {
        allCheckBoxesAreChecked = false;

        for (const quickSelectCheckBox of $(this.quickSelectCheckBoxTargets)) {
          if (quickSelectCheckBoxesToDisable.includes(quickSelectCheckBox)) {
            continue;
          } else if (this.checkboxSelectedByQuickSelectCheckBox($checkbox, $(quickSelectCheckBox))) {
            quickSelectCheckBoxesToDisable.push(quickSelectCheckBox);
          }
        }
      }
    }

    for (const quickSelectCheckBox of $(this.quickSelectCheckBoxTargets)) {
      $(quickSelectCheckBox).prop('checked', !quickSelectCheckBoxesToDisable.includes(quickSelectCheckBox));
    }

    $(this.hasSelectAllCheckBoxTarget && this.selectAllCheckBoxTarget).prop('checked', allCheckBoxesAreChecked);
  }

  checkboxSelectedByQuickSelectCheckBox($checkbox, $quickSelectCheckBox) {
    return $quickSelectCheckBox.data('filteredIds').includes($checkbox.data('id'));
  }
}
