import { Controller } from '@hotwired/stimulus';

export default class RowSelectionController extends Controller {
  static targets = ['currentRows', 'newRowTemplate', 'removeRowLink', 'rowIdField', 'rowSelect'];

  get $element() {
    return $(this.element);
  }

  connect() {
    this.$rowSelectTarget = $(this.rowSelectTarget);
    this.newRowTemplate = this.newRowTemplateTarget.content.firstElementChild.outerHTML;
    this.$rowSelectTarget.on('select2:select', this.handleRowSelectChange);
    this.$element.on('click', this.targets.getSelectorForTargetName('removeRowLink'), this.handleRemoveRowButtonClick);
  }

  disconnect() {
    this.$rowSelectTarget.off('select2:select', this.handleRowSelectChange);
    this.$element.off('click', this.targets.getSelectorForTargetName('removeRowLink'), this.handleRemoveRowButtonClick);
  }

  handleRowSelectChange = () => {
    const $selectedOption = this.$rowSelectTarget.find('option:selected');

    if (!this.allowDuplicateSelections()) {
      $selectedOption.prop('disabled', true); // Disable option
    }

    this.$rowSelectTarget.val(null).trigger('change'); // Clear selection
    this.insertNewRow($selectedOption);
  };

  handleRemoveRowButtonClick = (event) => {
    const currentRowsSelector = this.targets.getSelectorForTargetName('currentRows');
    const $rowToRemove = $(event.currentTarget).closest(`${currentRowsSelector} > tr`);

    $rowToRemove.remove();

    if (!this.allowDuplicateSelections()) {
      const rowIdFieldSelector = this.targets.getSelectorForTargetName('rowIdField');
      const rowId = $rowToRemove.find(rowIdFieldSelector).val();
      const $option = this.$rowSelectTarget.find(`option[value='${rowId}']`);

      $option.prop('disabled', false); // Re-enable option
    }

    event.preventDefault();
  };

  insertNewRow($selectedOption) {
    // Prevent ID clashes
    const newRowHtml = this.newRowTemplate.replace(/row_index_placeholder/g, new Date().getTime());
    const $newRow = $(newRowHtml.trim());

    this.completeNewRow($newRow, $selectedOption.data());
    $newRow.appendTo(this.currentRowsTarget);
  }

  // Override this method to allow a single row to be selected multiple times
  allowDuplicateSelections() {
    return false;
  }

  // Override this method to insert dynamic data into the new row
  completeNewRow($newRow, newRowData) {
    const rowIdFieldSelector = this.targets.getSelectorForTargetName('rowIdField');
    $newRow.find(rowIdFieldSelector).val(newRowData.id);
  }
}
