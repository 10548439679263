import { Controller } from '@hotwired/stimulus';

const DATE_FORMAT = 'YYYY-MM-DD';
const CUSTOM_PERIOD = 'custom_period';

export default class extends Controller {
  static targets = [
    'periodPresetInput',
    'specialPeriodIdInput',
    'tabs',
    'customPeriodOption',
    'presetOption',
    'specialPeriodOption',
    'calendar',
    'summaryBadge',
    'summaryText',
    'exclusiveEndDateInput',
  ];

  handleDateRangeCalendarChange = ({ detail: { startDate, endDate } }) => {
    if (!startDate || !endDate) {
      return;
    }

    this.activateMatchingOption(startDate, endDate);
    this.updateExclusiveEndDateInput(endDate);
    this.updateSummaryBadge(this.findMatchingOption(startDate, endDate)?.option);
    this.updateSummaryText(startDate, endDate);
  };

  handleCustomPeriodOptionClick = () => {
    this.setActiveOption(this.customPeriodOptionTarget);
    this.scrollOptionIntoView(this.customPeriodOptionTarget);
    this.updateSummaryBadge(null);
    this.periodPresetInputTarget.value = CUSTOM_PERIOD;
    this.specialPeriodIdInputTarget.value = '';
  };

  handleOptionClick = (event) => {
    const option = event.currentTarget;
    const startDate = parseDate(option.dataset.startDate);
    const endDate = parseDate(option.dataset.endDate);

    this.activateMatchingOption(startDate, endDate);
    this.updateCalendar(startDate, endDate);
    this.updateExclusiveEndDateInput(endDate);
    this.updateSummaryBadge(option);
    this.updateSummaryText(startDate, endDate);
  };

  updateExclusiveEndDateInput(endDate) {
    this.exclusiveEndDateInputTarget.value = endDate ? parseDate(endDate).add(1, 'days').format(DATE_FORMAT) : '';
  }

  updateCalendar(startDate, endDate) {
    const dateRangeCalendarController = this.application.getControllerForElementAndIdentifier(this.calendarTarget, 'date-range-calendar');
    dateRangeCalendarController.setDates(startDate, endDate);
  }

  activateMatchingOption(startDate, endDate) {
    const matchingOption = this.findMatchingOption(startDate, endDate);

    if (matchingOption) {
      this.setActiveTab(matchingOption.key);
      this.setActiveOption(matchingOption.option);
      this.scrollOptionIntoView(matchingOption.option);
      this.periodPresetInputTarget.value = matchingOption.option.dataset.periodPreset || '';
      this.specialPeriodIdInputTarget.value = matchingOption.option.dataset.specialPeriodId || '';
    } else {
      this.setActiveTab('periods');
      this.setActiveOption(this.customPeriodOptionTarget);
      this.scrollOptionIntoView(this.customPeriodOptionTarget);
      this.periodPresetInputTarget.value = CUSTOM_PERIOD;
      this.specialPeriodIdInputTarget.value = '';
    }
  }

  findMatchingOption(startDate, endDate) {
    const isMatch = (option) => {
      const optionStartDate = parseDate(option.dataset.startDate);
      const optionEndDate = parseDate(option.dataset.endDate);

      return startDate.isSame(optionStartDate, 'day') && endDate.isSame(optionEndDate, 'day');
    };

    const matchingPresetOption = this.presetOptionTargets.find(isMatch);
    const matchingSpecialPeriodOption = this.specialPeriodOptionTargets.find(isMatch);

    if (matchingPresetOption) {
      return { option: matchingPresetOption, key: 'periods' };
    } else if (matchingSpecialPeriodOption) {
      return { option: matchingSpecialPeriodOption, key: 'special_periods' };
    } else {
      return null;
    }
  }

  setActiveTab(key) {
    const tabsController = this.application.getControllerForElementAndIdentifier(this.tabsTarget, 'tabs');
    tabsController.setActive(key);
  }

  setActiveOption(option) {
    const toggleActive = (element, isActive) => {
      const popoverMenuItemController = this.application.getControllerForElementAndIdentifier(element, 'popover-menu-item');
      popoverMenuItemController.toggleActive(isActive);
    };

    [this.customPeriodOptionTarget, ...this.presetOptionTargets, ...this.specialPeriodOptionTargets].forEach((option) =>
      toggleActive(option, false)
    );

    toggleActive(option, true);
  }

  scrollOptionIntoView(option) {
    option.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  updateSummaryBadge(option) {
    if (option) {
      this.summaryBadgeTarget.style.display = '';
      this.summaryBadgeTarget.innerText = option.dataset.label;
    } else {
      this.summaryBadgeTarget.style.display = 'none';
    }
  }

  updateSummaryText(startDate, endDate) {
    if (startDate && endDate) {
      this.summaryTextTarget.innerText = formatDateRange(parseDate(startDate), parseDate(endDate));
    } else {
      this.summaryTextTarget.innerText = '—';
    }
  }
}

function parseDate(date) {
  return moment(date, DATE_FORMAT);
}

function formatDateRange(startDate, endDate) {
  if (startDate.isSame(endDate, 'day')) {
    return `${startDate.format('dd D MMM YYYY')}`;
  } else if (startDate.year() === endDate.year()) {
    return `${startDate.format('dd D MMM')} — ${endDate.format('dd D MMM YYYY')}`;
  } else {
    return `${startDate.format('dd D MMM YYYY')} — ${endDate.format('dd D MMM YYYY')}`;
  }
}
