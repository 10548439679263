import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['destroyCheckbox', 'destroyButton', 'removeButton'];

  connect() {
    $(this.destroyButtonTargets).on('click', this.handleDestroyButtonClick);
    $(this.removeButtonTargets).on('click', this.handleRemoveButtonClick);
  }

  disconnect() {
    $(this.destroyButtonTargets).off('click', this.handleDestroyButtonClick);
    $(this.removeButtonTargets).off('click', this.handleRemoveButtonClick);
  }

  // Removes the nested form from the DOM
  handleRemoveButtonClick = (event) => {
    const $element = $(this.element);
    const $parent = $element.parent();

    $element.remove();
    $parent.trigger('nestedFormRemove', $element);

    event.preventDefault();
  };

  // Hides the nested form and sets the _destroy hidden input
  handleDestroyButtonClick = () => {
    const $element = $(this.element);

    this.destroyCheckboxTarget.value = 1;
    this.destroyCheckboxTarget.dispatchEvent(new Event('change'));
    $element.hide();
  };
}
