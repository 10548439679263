import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  handleClick() {
    const url = this.data.get('url');
    const method = this.data.get('method');

    const $button = $(this.element);
    const $form = $button.closest('form');

    $form.attr('action', url);
    $form.attr('method', method);
  }
}
