import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggleAll', 'checkbox', 'actions', 'count', 'totalCount', 'selectAll', 'deselectAll', 'hiddenSelectAll'];

  get activeCheckboxes() {
    const visibleCheckboxes = $(this.checkboxTargets).filter(':visible').toArray();
    return visibleCheckboxes.filter((checkboxTarget) => !checkboxTarget.disabled);
  }

  get checkedCount() {
    return this.activeCheckboxes.filter((checkboxTarget) => checkboxTarget.checked).length;
  }

  connect() {
    if (this.checkboxTargets.length === 0) return;

    $(this.toggleAllTarget).on('change', this.handleToggleAllChange);
    $(this.checkboxTargets).on('change', this.handleCheckboxChange);
    $(this.hasSelectAllTarget && this.selectAllTarget).on('click', this.handleSelectAllClick);
    $(this.hasDeselectAllTarget && this.deselectAllTarget).on('click', this.handleDeselectAllClick);
    this.rerender();
  }

  disconnect() {
    if (this.checkboxTargets.length === 0) return;

    $(this.toggleAllTarget).off('change', this.handleToggleAllChange);
    $(this.checkboxTargets).off('change', this.handleCheckboxChange);
    $(this.hasSelectAllTarget && this.selectAllTarget).off('click', this.handleSelectAllClick);
    $(this.hasDeselectAllTarget && this.deselectAllTarget).off('click', this.handleDeselectAllClick);
  }

  selectCheckboxes(isToggled) {
    this.toggleAllTarget.checked = isToggled;

    for (const checkboxTarget of this.activeCheckboxes) {
      checkboxTarget.checked = isToggled;
    }
  }

  handleToggleAllChange = () => {
    this.selectCheckboxes(this.toggleAllTarget.checked);
    $(this.hasHiddenSelectAllTarget && this.hiddenSelectAllTarget).val(false);
    this.rerender();
  };

  handleCheckboxChange = () => {
    $(this.hasHiddenSelectAllTarget && this.hiddenSelectAllTarget).val(false);
    this.rerender();
  };

  handleSelectAllClick = () => {
    this.selectCheckboxes(true);
    $(this.hasHiddenSelectAllTarget && this.hiddenSelectAllTarget).val(true);
    this.rerender();
  };

  handleDeselectAllClick = () => {
    this.selectCheckboxes(false);
    $(this.hasHiddenSelectAllTarget && this.hiddenSelectAllTarget).val(false);
    this.rerender();
  };

  rerender() {
    const someSelected = this.checkedCount > 0;
    const allSelected = this.checkedCount === this.activeCheckboxes.length;

    $(this.actionsTarget).toggle(someSelected);
    $(this.countTarget).text(this.checkedCount);
    $(this.toggleAllTarget).prop('checked', allSelected);

    if (this.hasHiddenSelectAllTarget && this.hiddenSelectAllTarget.value === 'true') {
      $(this.totalCountTarget).show();
      $(this.countTarget).hide();
      $(this.hasSelectAllTarget && this.selectAllTarget).hide();
      $(this.hasDeselectAllTarget && this.deselectAllTarget).show();
    } else {
      $(this.totalCountTarget).hide();
      $(this.countTarget).show();
      $(this.hasSelectAllTarget && this.selectAllTarget).toggle(allSelected);
      $(this.hasDeselectAllTarget && this.deselectAllTarget).hide();
    }
  }
}
