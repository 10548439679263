import ToggleByController from './toggle_by_controller';

/**
 * Hides an element based on the state of a checkbox, radio, or select input.
 * @example
 * %input(value="value1" type="radio" name="my-radio-input") Radio 1
 * %input(value="value2" type="radio" name="my-radio-input") Radio 2
 * %input(value="value3" type="radio" name="my-radio-input") Radio 3
 *
 * %div(data-controller="hide-by" data-hide-by-radio="[name=my-radio-input]" data-hide-by-value="value1") Hidden when `Value 1` is checked
 * %div(data-controller="hide-by" data-hide-by-radio="[name=my-radio-input]" data-hide-by-values="value1,value2") Hidden when `Value 1` or `Value 2` is checked
 *
 * @example
 * %input(type="checkbox" name="my-checkbox-input") Checkbox
 * %div(data-controller="hide-by" data-hide-by-checkbox="[name=my-checkbox-input]") Hidden when checkbox is checked
 *
 * @example
 * %select(id="my_select")
 *   %option(value="value1") Value 1
 *   %option(value="value2") Value 2
 *
 * %div(data-controller="hide-by" data-hide-by-select="#my_select" data-hide-by-value="value1") Hidden when `Value 1` is selected
 */
export default class extends ToggleByController {
  toggle = (isToggled) => {
    $(this.element).toggle(!isToggled);
  };
}
