import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  get activeClass() {
    return 'active';
  }

  connect() {
    $(this.element).on('activate', this.handleActivate.bind(this));
    $(this.element).on('deactivate', this.handleDeactivate.bind(this));
  }

  handleActivate() {
    $(this.element).addClass(this.activeClass);
  }

  handleDeactivate() {
    $(this.element).removeClass(this.activeClass);
  }
}
