import RowSelectionController from '../row_selection_controller';

export default class extends RowSelectionController {
  completeNewRow($newRow, newRowData) {
    super.completeNewRow($newRow, newRowData);

    $newRow.children('.cost_link').html(newRowData.costLink);
    $newRow.children('.selectable').html(newRowData.selectable);
  }
}
