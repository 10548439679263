import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  handleClick(event) {
    event.preventDefault();

    navigator.clipboard.writeText(this.data.get('text')).then(
      () => {
        const onCopySuccess = this.data.get('onCopySuccess');

        if (onCopySuccess) {
          if (window.alertBox) {
            window.alertBox.addMessage(onCopySuccess);
          } else {
            window.alert(onCopySuccess);
          }
        }
      },
      () => {
        const onCopyFailure = this.data.get('onCopyFailure');

        if (onCopyFailure) {
          if (window.alertBox) {
            window.alertBox.addMessage(onCopyFailure, 'warning');
          } else {
            window.alert(onCopyFailure);
          }
        }
      }
    );
  }
}
