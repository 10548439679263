import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['moveTop', 'moveBottom'];

  get reorderableController() {
    return this.application.getControllerForElementAndIdentifier(this.element.parentNode, 'reorderable');
  }

  connect() {
    $(this.moveTopTargets).on('click', this.handleMoveTopClick);
    $(this.moveBottomTargets).on('click', this.handleMoveBottomClick);
  }

  disconnect() {
    $(this.moveTopTargets).off('click', this.handleMoveTopClick);
    $(this.moveBottomTargets).off('click', this.handleMoveBottomClick);
  }

  handleStart = () => {
    this.disableHoverState(this.handleTargets);
  };

  handleEnd = () => {
    this.enableHoverState(this.handleTargets);
    this.updateIndices(this.indexTargets);
  };

  handleMoveTopClick = () => {
    this.reorderableController.moveTop(this.element);
  };

  handleMoveBottomClick = () => {
    this.reorderableController.moveBottom(this.element);
  };
}
