import { Controller } from '@hotwired/stimulus';

const REFRESH_INTERVAL = 1000;
const REFRESH_STATUSES = ['pending', 'enqueued', 'processing'];
const COMPLETED_STATUSES = ['success', 'failed'];
const SUCCESS_STATUS = 'success';

export default class extends Controller {
  connect() {
    if (REFRESH_STATUSES.includes(this.data.get('status'))) {
      this.highlight();
      this.enqueueRefresh();
    }
  }

  disconnect() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  highlight = () => {
    $(this.element).effect('highlight', { color: 'var(--status-background)', duration: 1500 });
  };

  enqueueRefresh = () => {
    this.timer = setTimeout(this.refresh, REFRESH_INTERVAL);
  };

  refresh = () => {
    $.getJSON(this.data.get('url'), this.handleRefresh);
  };

  handleRefresh = ({ task, html }) => {
    if (this.data.get('status') === task.status) {
      this.enqueueRefresh();
    } else {
      if (COMPLETED_STATUSES.includes(task.status)) {
        this.handleTaskCompleted(task);
      }

      if (task.status === SUCCESS_STATUS) {
        this.handleTaskSuccess(task);
      }

      $(this.element).replaceWith(html);
    }
  };

  handleTaskCompleted = (task) => {
    const flash = (task.result && task.result.flash) || {};

    for (const [key, value] of Object.entries(flash)) {
      window.alertBox.addMessage(value, key);
    }

    window.userTasks.open();
    window.userTasks.load();
  };

  handleTaskSuccess = (task) => {
    if (task.attachments) {
      for (const attachment of task.attachments) {
        window.location.href = attachment.url;
      }
    }

    if (task.redirect_url) {
      window.location.href = task.redirect_url;
    }
  };
}
