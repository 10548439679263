import debounce from 'lodash.debounce';

export default class FormUpdater {
  constructor(formController, method = 'GET') {
    // Mimicks (deprecated) Nucleus component
    // TODO: Refactor rest of this class to use Stimulus targets etc
    const formComponent = {
      element: $(formController.element),
      find: (selector) => $(formController.element).find(`.js-${formController.identifier}-${selector}`),
    };

    this.form = formComponent.element;
    this.method = method;
    this.formComponent = formComponent;
    this.currentUrl = null;
    this.lastRequest = null;
    this.buttonsToDisable = this.form.find("[type='submit']:not([disabled='disabled'])");
    this.loaderTarget = formComponent.find('loader-target');
    this.loaderSection = formComponent.find('loader-section');
    this.debouncedSendRequest = debounce(this._sendRequest, 250);
  }

  refreshForm(url) {
    this.currentUrl = url;
    this._blockForm();
    this._abortLastRequest();
    this.debouncedSendRequest();
  }

  _sendRequest() {
    this.lastRequest = $.ajax({
      url: this.currentUrl,
      data: this._serializedFormData(),
      method: this.method, // if method is post, and posting hidden _method field, may be different on rails side
      success: () => {
        this._unBlockForm();
      },
    });
  }

  _serializedFormData() {
    return this.form.find('input, select, textarea').not('[data-form-updater-skipped]').serialize();
  }

  _abortLastRequest() {
    if (this.lastRequest) {
      this.lastRequest.abort();
      this.lastRequest = null;
    }
  }

  _blockForm() {
    this.loaderTarget.attachLoader('small');
    this._getLoaderSpinner().show();
    this.loaderSection.attachLoader('small', true);
    // disable the buttons to prevent the user (and mainly Capybara)
    // to proceed without seeing the full updated form.
    this.buttonsToDisable.attr('disabled', 'disabled');
  }

  _unBlockForm() {
    this.loaderTarget.removeLoader();
    this._getLoaderSpinner().hide();
    this.buttonsToDisable.removeAttr('disabled');
  }

  _getLoaderSpinner() {
    return this.formComponent.find('loader-spinner');
  }
}
