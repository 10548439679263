import { Controller } from '@hotwired/stimulus';

/**
 * Stops propagation on this element (for a specific event).
 * @example
 * %textarea(data-controller="stop-propagation" data-stop-propagation-event="keydown") Some content1
 */
export default class extends Controller {
  get event() {
    return this.data.get('event') || 'click';
  }

  connect() {
    $(this.element).on(this.event, this.handleEvent);
  }

  disconnect() {
    $(this.element).off(this.event, this.handleEvent);
  }

  handleEvent = (event) => {
    event.stopPropagation();
  };
}
