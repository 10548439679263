import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static classes = ['changed'];

  connect() {
    this.element.addEventListener('trix-initialize', this.handleTrixInitialize);
    this.element.addEventListener('change', this.handleChange);
  }

  disconnect() {
    this.element.removeEventListener('trix-initialize', this.handleTrixInitialize);
    this.element.removeEventListener('change', this.handleChange);
  }

  handleTrixInitialize = (e) => {
    // Because the hidden input that is added to the form by the trix editor is constantly re-created,
    // the defautValue of this hidden input is always the same as the value. So those hidden input changes
    // will not be detected. Therefore we have to save the initialvalue of the trix editor on initialize.
    e.target.setAttribute('defaultValue', e.target.value);
  };

  handleChange = (e) => {
    if (!this.hasChangedClass) return;

    if (this.isChangedField(e.target)) {
      e.target.classList.add(...this.changedClasses);
    } else {
      e.target.classList.remove(...this.changedClasses);
    }
  };

  isChangedField(field) {
    if (field.matches('trix-editor')) {
      return field.getAttribute('defaultValue') !== field.value;
    } else if (field.matches('select')) {
      const defaultOption = Array.from(field.options).find((option) => option.defaultSelected);
      return ((defaultOption || field.options[0])?.value || '') !== field.value;
    } else if (['radio', 'checkbox'].includes(field.type)) {
      return field.defaultChecked !== field.checked;
    } else if (field.dataset.controller?.includes('suggest')) {
      const defaultValue = JSON.parse(field.getAttribute('data-suggest-default-value')).join('');
      const formData = new FormData(this.element);
      const currentValue = formData.getAll(field.dataset.suggestNameValue).join('');
      return defaultValue !== currentValue;
    } else {
      return field.defaultValue !== field.value;
    }
  }

  get fields() {
    return this.element.querySelectorAll(
      [
        'textarea:not([data-changeset-excluded])',
        'input:not([data-changeset-excluded])',
        'select:not([data-changeset-excluded])',
        'trix-editor:not([data-changeset-excluded])',
        '[data-controller="suggest"]',
      ].join(',')
    );
  }

  get hasUnsavedChanges() {
    return [...this.fields].some((field) => this.isChangedField(field));
  }
}
