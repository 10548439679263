import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  copy() {
    const value = this.data.get('value');

    if (window.isSecureContext) {
      navigator.clipboard.writeText(value);
    } else {
      // Fallback for insecure contexts (localhost)
      window.prompt('', value);
    }
  }
}
