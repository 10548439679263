import { Controller } from '@hotwired/stimulus';
import FormUpdater from './form_updater';

export default class extends Controller {
  static targets = ['trigger'];

  constructor() {
    super(...arguments);
    this.formUpdater = new FormUpdater(this, this.data.get('refresh-method') || 'GET');
  }

  connect() {
    $(this.triggerTargets).on('change', this.handleTriggerChange);
    this.element['refreshingForm'] = this;
  }

  disconnect() {
    $(this.triggerTargets).off('change', this.handleTriggerChange);
  }

  handleTriggerChange = (event) => {
    const url = new URL(this.data.get('refresh-url'), window.location);

    if (event?.params?.params) {
      for (const [key, value] of Object.entries(event.params.params)) {
        url.searchParams.append(key, value);
      }
    }

    this.formUpdater.refreshForm(url.href);
  };
}
