import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'todoList'];

  connect() {
    $(this.todoListTarget).on('change', this.handleTodoListChange);
  }

  disconnect() {
    $(this.todoListTarget).off('change', this.handleTodoListChange);
  }

  handleTodoListChange = () => {
    this.reloadForm();
    this.disableFormElements();
  };

  reloadForm() {
    $(this.formTarget).attr('action', $(this.formTarget).data('url'));
    $(this.formTarget).attr('method', 'get');
    $(this.formTarget).attr('novalidate', true);

    $(this.formTarget).submit();
  }

  disableFormElements() {
    this.formTarget.elements.forEach((element) => $(element).prop('disabled', true));
  }
}
