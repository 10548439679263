import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  handleClick(event) {
    event.preventDefault();

    AjaxPopover.load($(this.element), this.data.get('url')).done(() => {
      // prettier-ignore
      $(this.element).closest('.reservation').fadeOut(function() {
        $(this).remove();

        const $checkinsTitle = $('[data-behaviour=checkins_title]')
        const $checkoutsTitle = $('[data-behaviour=checkouts_title]')

        const newCheckinsCount = $('.upcoming_checkins .reservation').length
        const newCheckoutsCount = $('.upcoming_checkouts .reservation').length

        $checkinsTitle.html($checkinsTitle.text().replace(/\d+ (.+)/, `${newCheckinsCount} $1`))
        $checkoutsTitle.html($checkoutsTitle.text().replace(/\d+ (.+)/, `${newCheckoutsCount} $1`))
      })
    });
  }
}
