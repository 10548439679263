/**
 * Liquid tag selection controller for a text area
 */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tag', 'input'];

  connect() {
    $(this.tagTargets).on('click', this.handleTagClick);
  }

  disconnect() {
    $(this.tagTargets).off('click', this.handleTagClick);
  }

  handleTagClick = (el) => {
    let cursorPos = $(this.inputTarget)[0].selectionStart || $(this.inputTarget).val().length;

    $(this.inputTarget).val((i, value) => {
      return value.substring(0, cursorPos) + '{{ ' + el.currentTarget.innerText + ' }}' + value.substring(cursorPos);
    });
  };
}
