import React, { useRef, useState } from 'react';
import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';
import { NextIcon, PrevIcon, renderMonthElement } from './react_dates';
import moment from '../lib/moment';

function DateRangeCalendar({ initialStartDate, initialEndDate, minDate, maxDate, onChange, onEndDateChange }) {
  const [startDate, setStartDate] = useState(initialStartDate ? moment(initialStartDate) : null);
  const [endDate, setEndDate] = useState(initialEndDate ? moment(initialEndDate) : null);
  const [focusedInput, setFocusedInput] = useState(null);

  const selectingStartDateRef = useRef(true);

  const handleDatesChange = ({ startDate, endDate }) => {
    if (selectingStartDateRef.current) {
      setStartDate(startDate);

      selectingStartDateRef.current = false;
    } else {
      setEndDate(endDate);
      onEndDateChange(endDate);

      selectingStartDateRef.current = true;
    }
    onChange(startDate, endDate);
  };

  const isOutsideRange = (day) => {
    return (minDate && moment(day).isBefore(minDate)) || (maxDate && moment(day).isAfter(maxDate));
  };

  return (
    <DayPickerRangeController
      startDate={startDate}
      endDate={endDate}
      isOutsideRange={isOutsideRange}
      onDatesChange={handleDatesChange}
      focusedInput={focusedInput || 'startDate'}
      onFocusChange={setFocusedInput}
      initialVisibleMonth={() => startDate || moment()}
      transitionDuration={0}
      noBorder
      hideKeyboardShortcutsPanel
      numberOfMonths={2}
      navPrev={<PrevIcon />}
      navNext={<NextIcon />}
      renderMonthElement={renderMonthElement}
    />
  );
}

export default DateRangeCalendar;
