import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field', 'autofill'];

  get $autofillTarget() {
    return this.hasAutofillTarget ? $(this.autofillTarget) : $();
  }

  connect() {
    this.$autofillTarget.on('ajax:success', this.handleAutofillAjaxSucces);
    this.$autofillTarget.on('ajax:error', this.handleAutofillAjaxError);
    this.$autofillTarget.on('ajax:before', this.handleAutofillAjaxBefore);
    this.$autofillTarget.on('ajax:complete', this.handleAutofillAjaxComplete);
  }

  disconnect() {
    this.$autofillTarget.off('ajax:success', this.handleAutofillAjaxSucces);
    this.$autofillTarget.off('ajax:error', this.handleAutofillAjaxError);
    this.$autofillTarget.off('ajax:before', this.handleAutofillAjaxBefore);
    this.$autofillTarget.off('ajax:complete', this.handleAutofillAjaxComplete);
  }

  handleAutofillAjaxSucces = (event, meter_readings) => {
    event.stopPropagation();

    $(this.fieldTarget).val('');

    for (const meter_reading of meter_readings) {
      const selector = [
        `[data-date='${meter_reading.date}']`,
        `[data-utility='${meter_reading.utility}']`,
        `[data-rate='${meter_reading.rate || ''}']`,
      ].join('');

      const $field = $(this.fieldTargets).filter(selector);
      const numberOfDecimals = $field.attr('step') % 1 ? $field.attr('step').toString().split('.')[1].length : 0;

      $field.val(meter_reading.value.toFixed(numberOfDecimals));
      $field.trigger('change');
    }
  };

  handleAutofillAjaxError = (event, xhr) => {
    const message = JSON.parse(xhr.responseText);
    window.alertBox.addMessage(message.error, 'alert', 5000);
  };

  handleAutofillAjaxBefore = () => {
    this.syncFieldDates();
    this.setCustomParams();

    this.$autofillTarget.prev('.loader-container').attachLoader('small');
    const $dots = this.$autofillTarget.prev('.loader-container').find('.m-loader--dots');
    $dots.addClass('inline-block');
  };

  handleAutofillAjaxComplete = () => {
    this.$autofillTarget.prev('.loader-container').removeLoader();
  };

  // Only used for checkin/checkout
  setCustomParams() {
    const customParams = {};
    const attributes = ['start-date', 'end-date'];

    for (const attribute of attributes) {
      const selector = this.$autofillTarget.data(`custom-${attribute}-input`);

      if (selector) {
        const date = $(selector).val();
        if (date) {
          customParams[attribute.replace('-', '_')] = date;
        }
      }
    }

    customParams['start_time'] = $('#checkin_checked_in_on_time').val();
    customParams['end_time'] = $('#checkout_checked_out_on_time').val();

    this.$autofillTarget.data('params', customParams);
  }

  // Only used for checkin/checkout
  syncFieldDates() {
    for (const field of $(this.fieldTargets).filter('[data-sync-date]')) {
      const $field = $(field);
      const date = $($field.data('sync-date')).val();
      if (date) {
        $field.attr('data-date', date);
      }
    }
  }
}
