import { Controller } from '@hotwired/stimulus';

function copyToClipboard(text) {
  const $input = $('<input>');
  $('body').append($input);
  $input.val(text).select();
  document.execCommand('copy');
  $input.remove();
}

function t(key) {
  return window.t(`.copy_to_clipboard.${key}`);
}

export default class extends Controller {
  connect() {
    this.initializeTooltip();
    this.setTooltipTitle(t('copy'));
  }

  initializeTooltip() {
    $(this.element).addClass('tooltip tooltip--sm tooltip--top');
  }

  setTooltipTitle(title) {
    $(this.element).attr('data-title', title);
  }

  handleClick(event) {
    event.preventDefault();

    copyToClipboard(this.data.get('text'));
    this.setTooltipTitle(t('copied'));

    setTimeout(() => this.setTooltipTitle(t('copy')), 5000);
  }
}
