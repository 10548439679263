import { Controller } from '@hotwired/stimulus';

function isDirtyInput(input) {
  if (input.type === 'checkbox') {
    return input.defaultChecked !== input.checked;
  } else {
    return input.defaultValue !== input.value;
  }
}

function isDirtySelect(select) {
  const defaultOption = Array.from(select.options).find((option) => option.defaultSelected);
  return (defaultOption || select.options[0] || {}).value !== select.value;
}

export default class extends Controller {
  static targets = ['actions', 'submit'];

  connect() {
    this.$inputs.on('keyup', this.handleInputKeyup);
    this.$inputs.on('change', this.handleInputChange);
    this.$selects.on('change', this.handleSelectChange);
  }

  disconnect() {
    this.$inputs.off('keyup', this.handleInputKeyup);
    this.$inputs.off('change', this.handleInputChange);
    this.$selects.off('change', this.handleSelectChange);
  }

  get $inputs() {
    return $(this.element).find('input:not([data-dirty-excluded])');
  }

  get $selects() {
    return $(this.element).find('select:not([data-dirty-excluded])');
  }

  get dirtyClass() {
    return this.data.get('dirtyClass');
  }

  get isDirty() {
    return this.$inputs.hasClass(this.dirtyClass) || this.$selects.hasClass(this.dirtyClass);
  }

  handleInputKeyup = ({ target }) => {
    $(target).toggleClass(this.dirtyClass, isDirtyInput(target));
    this.toggleActions();
    this.toggleSubmits();
  };

  handleInputChange = ({ target }) => {
    $(target).toggleClass(this.dirtyClass, isDirtyInput(target));
    this.toggleActions();
    this.toggleSubmits();
  };

  handleSelectChange = ({ target }) => {
    $(target).toggleClass(this.dirtyClass, isDirtySelect(target));
    this.toggleActions();
    this.toggleSubmits();
  };

  toggleSubmits() {
    if (this.hasSubmitTarget) {
      $(this.submitTarget).prop('disabled', !this.isDirty);
    }
  }

  toggleActions() {
    if (this.hasActionsTarget) {
      $(this.actionsTarget)[this.isDirty ? 'slideDown' : 'slideUp']('fast');
    }
  }
}
