import { Controller } from '@hotwired/stimulus';

export function parseDecimal(value) {
  const decimalValue = parseFloat((value || '0').replace(',', '.'));
  return decimalValue ? decimalValue : 0.0;
}

export function formatAmount(amount, separator) {
  return amount.toFixed(2).replace('.', separator);
}

export default class extends Controller {
  static targets = [
    'price',
    'totalOpen',
    'totalOpenForPage',
    'totalPrice',
    'totalPriceForPage',
    'totalDifference',
    'totalDifferenceForPage',
  ];

  get separator() {
    return this.data.get('separator');
  }

  get $totalPriceForPageTarget() {
    return this.hasTotalPriceForPageTarget ? $(this.totalPriceForPageTarget) : $();
  }

  get $totalOpenForPageTarget() {
    return this.hasTotalOpenForPageTarget ? $(this.totalOpenForPageTarget) : $();
  }

  get $totalDifferenceForPageTarget() {
    return this.hasTotalDifferenceForPageTarget ? $(this.totalDifferenceForPageTarget) : $();
  }

  handlePriceKeyup() {
    this.setTotalPrice();
    this.setTotalDifference();
  }

  setTotalPrice() {
    const totalPrice = this.priceTargets.reduce((sum, priceTarget) => {
      return sum + parseDecimal($(priceTarget).val());
    }, 0);

    $(this.totalPriceTarget).html(formatAmount(totalPrice, this.separator));
    this.$totalPriceForPageTarget.html(formatAmount(totalPrice, this.separator));
  }

  setTotalDifference() {
    const totalOpenForPage = parseDecimal(this.$totalOpenForPageTarget.text());
    const totalPriceForPage = parseDecimal(this.$totalPriceForPageTarget.text());

    const totalOpen = parseDecimal($(this.totalOpenTarget).text());
    const totalPrice = parseDecimal($(this.totalPriceTarget).text());

    const totalDifferenceForPage = totalOpenForPage - totalPriceForPage;
    const totalDifference = totalOpen - totalPrice;

    this.$totalDifferenceForPageTarget.html(formatAmount(totalDifferenceForPage, this.separator));
    $(this.totalDifferenceTarget).html(formatAmount(totalDifference, this.separator));
  }
}
