import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  handleSubmit(event) {
    event.preventDefault();
    this.validate();
  }

  validate() {
    let data = $(this.element).serializeArray();

    // Strip _method from data to ensure POST request
    data = data.filter(({ name }) => name !== '_method');

    $.post(this.data.get('validate-url'), data).done(this.handleValidateSuccess.bind(this)).fail(this.handleValidateFailure.bind(this));
  }

  handleValidateSuccess() {
    // Continue with submitting form if validated succesfully
    this.element.submit();
  }

  handleValidateFailure(xhr) {
    // Rerender form if it has errors
    const $formData = $('<div/>').html(xhr.responseText);

    $(this.element).parent().html($formData.html()); // prettier-ignore
    $(document).trigger('reveal.facebox');
  }
}
