import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  get $elementTarget() {
    return $(this.data.get('element'));
  }

  connect() {
    $(this.element).on('click', this.handleClick);
  }

  disconnect() {
    $(this.element).off('click', this.handleClick);
  }

  handleClick = () => {
    $.facebox(this.$elementTarget.show());
  };
}
