import { Controller } from '@hotwired/stimulus';
import Tribute from 'tributejs';

export default class extends Controller {
  connect() {
    var tribute = new Tribute({
      values: JSON.parse(this.data.get('users')),
      noMatchTemplate: function () {
        return '<span style:"visibility: hidden;"></span>';
      },
      menuItemTemplate: function (item) {
        return '<div class="flex flex-center">' + item.original.avatar + '<div class="ml-2 ml-05">' + item.string + '</div></div>';
      },
    });

    tribute.attach(this.element);
  }
}
