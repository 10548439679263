import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['paymentMethodSelector', 'notificationCheckbox'];

  initialize() {
    this.setNotificationCheckboxValue();
  }

  setNotificationCheckboxValue(_) {
    const selected = this.paymentMethodSelectorTarget.querySelector('option:checked');
    this.notificationCheckboxTarget.checked = selected.getAttribute('data-send-notification') == '1';
  }
}
