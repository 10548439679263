import { Controller } from '@hotwired/stimulus';
import { Loader } from '@googlemaps/js-api-loader';

export default class extends Controller {
  static targets = ['latitude', 'longitude', 'address', 'map'];
  connect() {
    const loader = new Loader({
      apiKey: this.data.get('apiKey'),
      version: 'weekly',
      libraries: ['maps', 'places'],
      language: this.data.get('i18n-locale').split('-')[0],
    });

    loader.load().then(() => {
      this.initializeMap();
    });
  }

  initializeMap() {
    if (!this.latitudeTarget.value && !this.longitudeTarget.value) {
      // Set initial location (Booking Experts HQ)
      this.latitudeTarget.value = 52.2393909;
      this.longitudeTarget.value = 6.8366674;
    }

    // Draw map
    this.map = new google.maps.Map(this.mapTarget, { center: this.getLocation(), zoom: 13 });
    // Add marker
    this.marker = new google.maps.Marker({ position: this.getLocation(), map: this.map, draggable: true });

    // Add autocomplete
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget);

    google.maps.event.addListener(this.marker, 'dragend', this.handleMarkerChanged);
    google.maps.event.addListener(this.autocomplete, 'place_changed', this.handlePlaceChanged);
    google.maps.event.addListener(this.map);
  }

  disconnect() {
    if (typeof google != 'undefined') {
      google.maps.event.clearListeners(this.marker, 'dragend');
      google.maps.event.clearListeners(this.autocomplete, 'place_changed');
      google.maps.event.clearListeners(this.map);
    }
  }

  handleAddressKeydown(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  handleMarkerChanged = () => {
    this.latitudeTarget.value = this.marker.position.lat();
    this.longitudeTarget.value = this.marker.position.lng();
    this.map.setCenter(this.getLocation());
  };

  handlePlaceChanged = () => {
    const { geometry } = this.autocomplete.getPlace();

    if (geometry) {
      this.marker.setPosition(geometry.location);
      this.handleMarkerChanged();
    }
  };

  getLocation = () => {
    return new google.maps.LatLng(this.latitudeTarget.value, this.longitudeTarget.value);
  };
}
