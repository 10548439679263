import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash.debounce';

export default class extends Controller {
  static targets = ['emptyState', 'suggestions'];

  fetchCustomers = debounce(() => {
    this.suggestionsTarget.innerHTML = '';
    this.suggestionsTarget.style.display = 'block';
    this.emptyStateTarget.style.display = 'none';

    let params = {};

    this.addParam(params, 'search[company]', this.element.booking_company.value);
    this.addParam(params, 'search[first_name]', this.element.booking_first_name.value);
    this.addParam(params, 'search[last_name]', this.element.booking_last_name.value);
    this.addParam(params, 'search[email]', this.element.booking_email.value);
    this.addParam(params, 'search[postalcode]', this.element.booking_postalcode.value);
    this.addParam(params, 'search[address]', this.element.booking_address.value);

    // If there are no parameters, hide the suggestions and show emptyState
    if (Object.keys(params).length === 0) {
      this.suggestionsTarget.style.display = 'none';
      this.emptyStateTarget.style.display = 'block';

      return;
    }

    fetch(this.data.get('source-url') + '?' + new URLSearchParams(params), {
      headers: {
        Accept: 'text/html',
      },
    })
      .then((response) => response.text())
      .then((html) => {
        this.suggestionsTarget.innerHTML = html;
      });
  }, 300);

  fillInputs = (event) => {
    const customer = event.params.payload;

    this.element.booking_last_name.value = customer.last_name;
    this.element.booking_first_name.value = customer.first_name;
    this.element.booking_email.value = customer.email;
    this.element.booking_postalcode.value = customer.postalcode;
    this.element.booking_address.value = customer.address;
    this.element.booking_city.value = customer.city;
    this.element.booking_phone.value = customer.phone;
    this.element.booking_country_code.value = customer.country_code;
    this.element.booking_company.value = customer.company;
    this.element.booking_vat_nr.value = customer.vat_nr;
    this.element.booking_date_of_birth.value = customer.date_of_birth;
    this.element.booking_receive_newsletter.checked = customer.receive_newsletter;
    this.element.booking_locale.value = customer.locale;
    this.element.booking_locale.dispatchEvent(new Event('change'));

    // Handles this.element.booking_title as a NodeList when multiple locales exist, otherwise treat it as a single node.
    if (NodeList.prototype.isPrototypeOf(this.element.booking_title)) {
      this.element.booking_title.forEach((element) => {
        element.value = customer.title;
      });
    } else {
      this.element.booking_title.value = customer.title;
    }

    const isCompany = customer.company !== null;

    this.element.booking_is_company_true.checked = isCompany;
    this.element.booking_is_company_false.checked = !isCompany;

    this.element.booking_is_company_true.dispatchEvent(new Event('change'));
    this.element.booking_is_company_false.dispatchEvent(new Event('change'));
  };

  addParam = (params, key, value) => {
    if (value) {
      params[key] = value;
    }
  };
}
