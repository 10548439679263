import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { id: String };
  static targets = ['retranslateHiddenInput', 'retranslateIcon'];

  connect() {
    window.addEventListener('translation-control.retranslate', this.handleRetranslate);
  }

  handleRetranslate = (e) => {
    if (e.detail.id !== this.idValue) return;

    this.retranslateHiddenInputTarget.toggleAttribute('disabled', !e.detail.value);
    this.retranslateIconTarget.toggleAttribute('hidden', !e.detail.value);
  };
}
