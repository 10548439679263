import { Controller } from '@hotwired/stimulus';
import tippy from 'tippy.js';

const DEFAULT_POPPER_OPTIONS = {
  arrow: false,
  theme: 'popover', // Theme styles are defined in vendor/tippy.css
  allowHTML: true,
  interactive: true,
  trigger: 'click',
  placement: 'bottom-start',
  maxWidth: 'none',
  duration: 0,
  offset: [0, 8],
  zIndex: 0,
};

export default class extends Controller {
  static targets = ['pane', 'trigger'];

  connect() {
    this.tippyInstance = tippy(this.triggerTarget, {
      ...DEFAULT_POPPER_OPTIONS,
      content: this.paneTarget.content.cloneNode(true),
      appendTo: this.portal,
      onShown: this.handleShown,
      onShow: this.handleShow,
      onHide: this.handleHide,
      ...this.popperOptions,
    });
  }

  disconnect() {
    this.tippyInstance.destroy();
    this.tippyInstance = null;
  }

  handleShown = ({ popper }) => {
    $(popper).find('[autofocus], [data-autofocus]').trigger('focus');
  };

  handleShow = () => {
    $(this.triggerTarget).addClass(this.activeClass);
  };

  handleHide = () => {
    $(this.triggerTarget).removeClass(this.activeClass);
  };

  // Looks up popper.js options via data attributes.
  // For example, use data-popover-placement="bottom-end" to change the placement option.
  get popperOptions() {
    const options = {};

    for (const key of ['placement', 'modifiers', 'theme']) {
      if (this.data.has(key)) {
        options[key] = this.data.get(key);
      }
    }

    return options;
  }

  get activeClass() {
    return $(this.triggerTarget).data('activeClass') || 'active';
  }

  get portal() {
    return document.getElementById('portal');
  }
}
