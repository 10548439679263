import { Controller } from '@hotwired/stimulus';
import React from 'react';
import ReactDOM from 'react-dom';

export default class extends Controller {
  static targets = ['input', 'component'];

  initialize() {
    this.component = import(`../react_components/date_calendar.jsx`);
    this.mounted = false;
  }

  connect() {
    $(document).on('tabsPanel.toggleActive', this.handleTabsPanelToggleActive);

    this.mountComponentIfVisible();
  }

  disconnect() {
    $(document).off('tabsPanel.toggleActive', this.handleTabsPanelToggleActive);

    this.unmountComponent();
  }

  handleTabsPanelToggleActive = (e, tabsPanel, isActive) => {
    if (tabsPanel.contains(this.element) && isActive && !this.mounted) {
      this.mountComponentIfVisible();
    }
  };

  handleChange = (date) => {
    if (date !== null) {
      this.inputTarget.value = date.format('YYYY-MM-DD');
    }
    this.inputTarget.dispatchEvent(new CustomEvent('datecalendar.change', { bubbles: true, detail: { value: date } }));
  };

  mountComponentIfVisible = () => {
    if ($(this.element).is(':visible')) {
      this.mountComponent();
    }
  };

  mountComponent = async () => {
    const DateCalendar = await this.component;

    ReactDOM.render(
      <DateCalendar.default
        initialDate={this.inputTarget.value}
        minDate={this.data.get('min')}
        maxDate={this.data.get('max')}
        onChange={this.handleChange}
      />,
      this.componentTarget
    );

    this.mounted = true;
  };

  unmountComponent = () => {
    ReactDOM.unmountComponentAtNode(this.componentTarget);
    this.mounted = false;
  };
}
