import ToggleByController from './toggle_by_controller';

/**
 * Checks a checkbox based on the state of another checkbox.
 *
 * @example
 * %input(type="checkbox" name="my-checkbox-input")
 * %input(type="checkbox" data-controller="check-by" data-check-by-checkbox="[name=my-checkbox-input]")
 */
export default class extends ToggleByController {
  toggle = (isToggled) => {
    $(this.element).prop('checked', isToggled);
  };
}
