import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.resizeObserver = new ResizeObserver(() => {
      this.element.style.height = `${this.element.contentDocument.documentElement.scrollHeight + this.borderWidth}px`;
    });

    this.element.addEventListener('load', this.handleLoad);
  }

  get borderWidth() {
    const computedStyle = window.getComputedStyle(this.element);
    return parseInt(computedStyle.borderTopWidth) + parseInt(computedStyle.borderBottomWidth);
  }

  disconnect() {
    this.resizeObserver.disconnect();
    this.element.removeEventListener('load', this.handleLoad);
  }

  handleLoad = () => {
    this.resizeObserver.observe(this.element.contentDocument.documentElement);
  };
}
