import c0 from './activatable_controller';
import c1 from './application_layout_controller';
import c2 from './autocomplete_controller';
import c3 from './autosubmit_controller';
import c4 from './changeset_controller';
import c5 from './check_by_controller';
import c6 from './clipboard_controller';
import c7 from './color_picker_controller';
import c8 from './date_calendar_controller';
import c9 from './date_picker_controller';
import c10 from './date_range_calendar_controller';
import c11 from './disable_by_controller';
import c12 from './disable_suggest_by_controller';
import c13 from './enable_by_controller';
import c14 from './enable_suggest_by_controller';
import c15 from './expandable_controller';
import c16 from './expandable_list_controller';
import c17 from './hide_by_controller';
import c18 from './highlight_controller';
import c19 from './hotkey_controller';
import c20 from './iframe_auto_height_controller';
import c21 from './image_preview_controller';
import c22 from './inline_validate_controller';
import c23 from './localized_field_control_controller';
import c24 from './localized_field_controller';
import c25 from './localized_field_menu_item_controller';
import c26 from './location_input_controller';
import c27 from './modal_controller';
import c28 from './modal_dialog_controller';
import c29 from './modal_trigger_controller';
import c30 from './nested_form_controller';
import c31 from './nested_form_entry_controller';
import c32 from './notification_controller';
import c33 from './notifications_controller';
import c34 from './periodic_refresh_controller';
import c35 from './popover_controller';
import c36 from './popover_menu_item_controller';
import c37 from './prevent_default_controller';
import c38 from './reorderable_controller';
import c39 from './search_list_controller';
import c40 from './select_all_controller';
import c41 from './select_bulk_controller';
import c42 from './show_by_controller';
import c43 from './stop_propagation_controller';
import c44 from './suggest_controller';
import c45 from './tabs_controller';
import c46 from './tabs_item_controller';
import c47 from './tabs_panel_controller';
import c48 from './toggle_by_controller';
import c49 from './tooltip_controller';
import c50 from './translation_control_controller';
import c51 from './updating_form_controller';
export const definitions = [
	{identifier: 'activatable', controllerConstructor: c0},
	{identifier: 'application-layout', controllerConstructor: c1},
	{identifier: 'autocomplete', controllerConstructor: c2},
	{identifier: 'autosubmit', controllerConstructor: c3},
	{identifier: 'changeset', controllerConstructor: c4},
	{identifier: 'check-by', controllerConstructor: c5},
	{identifier: 'clipboard', controllerConstructor: c6},
	{identifier: 'color-picker', controllerConstructor: c7},
	{identifier: 'date-calendar', controllerConstructor: c8},
	{identifier: 'date-picker', controllerConstructor: c9},
	{identifier: 'date-range-calendar', controllerConstructor: c10},
	{identifier: 'disable-by', controllerConstructor: c11},
	{identifier: 'disable-suggest-by', controllerConstructor: c12},
	{identifier: 'enable-by', controllerConstructor: c13},
	{identifier: 'enable-suggest-by', controllerConstructor: c14},
	{identifier: 'expandable', controllerConstructor: c15},
	{identifier: 'expandable-list', controllerConstructor: c16},
	{identifier: 'hide-by', controllerConstructor: c17},
	{identifier: 'highlight', controllerConstructor: c18},
	{identifier: 'hotkey', controllerConstructor: c19},
	{identifier: 'iframe-auto-height', controllerConstructor: c20},
	{identifier: 'image-preview', controllerConstructor: c21},
	{identifier: 'inline-validate', controllerConstructor: c22},
	{identifier: 'localized-field-control', controllerConstructor: c23},
	{identifier: 'localized-field', controllerConstructor: c24},
	{identifier: 'localized-field-menu-item', controllerConstructor: c25},
	{identifier: 'location-input', controllerConstructor: c26},
	{identifier: 'modal', controllerConstructor: c27},
	{identifier: 'modal-dialog', controllerConstructor: c28},
	{identifier: 'modal-trigger', controllerConstructor: c29},
	{identifier: 'nested-form', controllerConstructor: c30},
	{identifier: 'nested-form-entry', controllerConstructor: c31},
	{identifier: 'notification', controllerConstructor: c32},
	{identifier: 'notifications', controllerConstructor: c33},
	{identifier: 'periodic-refresh', controllerConstructor: c34},
	{identifier: 'popover', controllerConstructor: c35},
	{identifier: 'popover-menu-item', controllerConstructor: c36},
	{identifier: 'prevent-default', controllerConstructor: c37},
	{identifier: 'reorderable', controllerConstructor: c38},
	{identifier: 'search-list', controllerConstructor: c39},
	{identifier: 'select-all', controllerConstructor: c40},
	{identifier: 'select-bulk', controllerConstructor: c41},
	{identifier: 'show-by', controllerConstructor: c42},
	{identifier: 'stop-propagation', controllerConstructor: c43},
	{identifier: 'suggest', controllerConstructor: c44},
	{identifier: 'tabs', controllerConstructor: c45},
	{identifier: 'tabs-item', controllerConstructor: c46},
	{identifier: 'tabs-panel', controllerConstructor: c47},
	{identifier: 'toggle-by', controllerConstructor: c48},
	{identifier: 'tooltip', controllerConstructor: c49},
	{identifier: 'translation-control', controllerConstructor: c50},
	{identifier: 'updating-form', controllerConstructor: c51},
];
