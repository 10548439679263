import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['cash', 'cashTotal', 'subtotal', 'total', 'difference'];

  connect() {
    this.updateFields();
  }

  handleChange() {
    this.updateFields();
  }

  updateFields() {
    this.updateCashTotal();
    this.updateTotal();
    // Wait for child controller to be available
    window.requestAnimationFrame(() => this.updateDifferences());
  }

  get cashTotal() {
    return this.cashTargets.reduce((sum, cashTarget) => {
      const cashUnit = parseFloat($(cashTarget).data('cash-unit'));
      const amount = parseFloat($(cashTarget).val() || 0);
      return sum + cashUnit * amount;
    }, 0);
  }

  get total() {
    return this.subtotalTargets.reduce((sum, subtotalTarget) => sum + parseFloat($(subtotalTarget).val()), 0);
  }

  updateCashTotal() {
    // Update (hidden) cash total inputs
    $(this.cashTotalTargets).val(this.cashTotal.toFixed(2));
  }

  updateTotal() {
    $(this.totalTarget).val(this.total.toFixed(2));
  }

  updateDifferences() {
    this.differenceTargets.forEach((differenceTarget) => {
      const controller = this.application.getControllerForElementAndIdentifier(differenceTarget, 'register-count-difference');
      controller.updateDifference();
    });
  }
}
