import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'consentBlocks',
    'consentNecessary',
    'consentPreferences',
    'consentStatistics',
    'consentMarketing',
    'saveButton',
    'actionButtons',
    'infoBar',
    'status',
    'consentId',
    'consentDate',
  ];

  connect() {
    this.updateCookieSettings();
    this.toggleConsentBlocksBasedOnQueryParam();
    this.enableSaveButtonOnChange();
    this.checkBexConsentCookie();
  }

  i18n(key) {
    const i18nData = JSON.parse(this.element.dataset.i18n);
    return i18nData[key];
  }

  toggleConsentBlocksBasedOnQueryParam() {
    const showConsentSettings = new URLSearchParams(window.location.search).get('show_consent_settings');
    this.consentBlocksTarget?.classList.toggle('hidden', showConsentSettings !== 'true');
  }

  enableSaveButtonOnChange() {
    this.consentPreferencesTarget.addEventListener('change', this.enableSaveButton.bind(this));
    this.consentStatisticsTarget.addEventListener('change', this.enableSaveButton.bind(this));
    this.consentMarketingTarget.addEventListener('change', this.enableSaveButton.bind(this));
  }

  checkBexConsentCookie() {
    const bexConsent = this.getCookie('BexConsent');
    if (!bexConsent) {
      this.hideActionButtons();
      this.openConsentBlocks();
      this.hideInfoBar();
    }
  }

  toggleConsentBlocks() {
    const urlParams = new URLSearchParams(window.location.search);
    const showConsentSettings = urlParams.get('show_consent_settings');
    const newShowConsentSettings = showConsentSettings === 'true' ? 'false' : 'true';

    this.consentBlocksTarget?.classList.toggle('hidden', newShowConsentSettings !== 'true');

    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('show_consent_settings', newShowConsentSettings);

    history.pushState(null, '', newUrl.toString());
  }

  save() {
    const { consentNecessaryTarget, consentPreferencesTarget, consentStatisticsTarget, consentMarketingTarget } = this;

    if (consentNecessaryTarget && consentPreferencesTarget && consentStatisticsTarget && consentMarketingTarget) {
      const cookieValue = {
        necessary: consentNecessaryTarget.checked,
        preferences: consentPreferencesTarget.checked,
        statistics: consentStatisticsTarget.checked,
        marketing: consentMarketingTarget.checked,
        method: 'explicit',
        ver: 1,
        utc: Date.now(),
      };

      const expires = new Date();
      expires.setFullYear(expires.getFullYear() + 10); // Set expiration date to 10 years from now

      document.cookie = `BexConsent=${JSON.stringify(cookieValue)}; path=/; expires=${expires.toUTCString()};`;

      location.reload();
    } else {
      console.error('One or more consent targets are missing.');
    }
  }

  updateCookieSettings() {
    const cookieValue = this.getCookie('BexConsent');
    if (!cookieValue) return;

    let settings;
    try {
      settings = JSON.parse(cookieValue);
    } catch (error) {
      console.error('Failed to parse cookie value:', error);
      return;
    }

    this.updateStatus(settings);
    this.updateConsentId(cookieValue);
    this.updateConsentDate(settings);
    this.updateCheckboxes(settings);
  }

  getCookie(name) {
    if (!name) return null;

    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop()?.split(';').shift() || null;
    }

    return null;
  }

  removeConsent(event) {
    event.preventDefault();
    if (!confirm(this.i18n('withdrawConsentMessage'))) return;

    const cookieValue = this.getCookie('BexConsent');
    if (!cookieValue) return;

    let settings;
    try {
      settings = JSON.parse(cookieValue);
    } catch (error) {
      console.error('Failed to parse cookie value:', error);
      return;
    }

    settings.preferences = false;
    settings.statistics = false;
    settings.marketing = false;
    document.cookie = `BexConsent=${JSON.stringify(settings)}; path=/;`;

    location.reload();
  }

  updateStatus(settings) {
    if (!settings || typeof settings !== 'object') return;

    const { necessary, preferences, statistics, marketing } = settings;
    let statusText = this.i18n('selectedStatusText');
    const selectedConsents = [];

    if (necessary) selectedConsents.push(this.i18n('necessary'));
    if (preferences) selectedConsents.push(this.i18n('preferences'));
    if (statistics) selectedConsents.push(this.i18n('statistics'));
    if (marketing) selectedConsents.push(this.i18n('marketing'));

    if (selectedConsents.length === 0) {
      statusText = this.i18n('noConsentSelectedText');
    } else if (selectedConsents.length === 1 && necessary) {
      statusText = this.i18n('onlyNecessarySelectedText');
    } else if (selectedConsents.length === 4) {
      statusText = this.i18n('allConsentsSelectedText');
    } else {
      statusText += selectedConsents.join(', ');
    }

    this.statusTarget.textContent = statusText;
  }

  updateConsentId(cookieValue) {
    if (!cookieValue || typeof cookieValue !== 'string') {
      this.consentIdTarget.textContent = 'N/A';
      return;
    }

    const simpleHash = this.calculateSimpleHash(cookieValue);
    this.consentIdTarget.textContent = btoa(simpleHash.toString()) || 'N/A';
  }

  calculateSimpleHash(value) {
    if (typeof value !== 'string') {
      throw new TypeError('Input must be a string');
    }

    let hash = 5381;
    for (const char of value) {
      hash = (hash * 33) ^ char.charCodeAt(0);
    }
    return hash >>> 0;
  }

  updateConsentDate(settings) {
    const date = new Date(settings.utc);
    this.consentDateTarget.textContent = date.toLocaleString();
  }

  updateCheckboxes(settings) {
    this.consentNecessaryTarget.checked = settings.necessary;
    this.consentPreferencesTarget.checked = settings.preferences;
    this.consentStatisticsTarget.checked = settings.statistics;
    this.consentMarketingTarget.checked = settings.marketing;
  }

  enableSaveButton() {
    this.saveButtonTarget.disabled = false;
    this.saveButtonTarget.classList.remove('button--disabled');
  }

  hideActionButtons() {
    this.actionButtonsTarget.style.display = 'none';
  }

  openConsentBlocks() {
    this.consentBlocksTarget.classList.remove('hidden');
  }

  hideInfoBar() {
    this.infoBarTarget.style.display = 'none';
  }
}
