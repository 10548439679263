import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['validationResult', 'continueButton'];

  validationResultTargetConnected() {
    this.updateContinueButtonDisabled();
  }

  validationResultTargetDisconnected() {
    this.updateContinueButtonDisabled();
  }

  updateContinueButtonDisabled() {
    if (this.validationResultTargets.length !== this.validationResultTargets.filter((t) => t.dataset.result === 'valid').length) {
      this.continueButtonTarget.setAttribute('disabled', 'disabled');
    } else {
      this.continueButtonTarget.removeAttribute('disabled');
    }
  }
}
