import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['maxRetentionPeriod'];

  setRetention(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const retentionPeriod = button.dataset.retention;
    if (this.hasMaxRetentionPeriodTarget) {
      this.maxRetentionPeriodTarget.querySelector('input').value = retentionPeriod;
    }
  }
}
